import { Form, Modal, Select, Skeleton } from "antd";
import { useFetchSkipTraceWorkflowsQuery } from "features/bulkActions/bulkActionsAPI";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";

export default function SkipTracingOptionsModal({ open, onSubmit, onCancel, title, provider }) {
  const {
    data: skipTraceWorkflows = [],
    isSuccess: isSkipTraceSuccess,
    isLoading: isSkipTraceLoading,
  } = useFetchSkipTraceWorkflowsQuery({
    provider,
  });

  const { data: constants } = useFetchBackendConstantsQuery();

  const [form] = Form.useForm();

  const initialValues = {
    skipTraceSettingId: skipTraceWorkflows.length === 1 ? skipTraceWorkflows[0].id : undefined,
  };

  const onOk = async () => {
    const values = await form.validateFields();
    onSubmit(values);
  };

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      maskClosable={false}
      onOk={onOk}
      width={900}
      title={title}
    >
      {!isSkipTraceSuccess ? (
        <Skeleton active />
      ) : (
        <Form
          layout="vertical"
          form={form}
          initialValues={initialValues}
          validateMessages={{ required: "This is a required field" }}
        >
          <Form.Item
            name="skipTraceSettingId"
            label="Skip Trace Workflow"
            required
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              placeholder="Select one..."
              allowClear
              options={skipTraceWorkflows.map(({ id, workflowName }) => ({
                label: `ID: ${id} (Name: ${workflowName})`,
                value: id,
              }))}
              loading={isSkipTraceLoading}
            />
          </Form.Item>

          {provider === "locate_smarter" && (
            <Form.Item
              name="locateSmarterProduct"
              label="Product"
              required
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                popupMatchSelectWidth={false}
                placeholder="Select one..."
                allowClear
                options={constants?.locateSmarterProducts.map((product) => ({
                  label: product.display,
                  value: product.value,
                }))}
              />
            </Form.Item>
          )}
        </Form>
      )}
    </Modal>
  );
}
