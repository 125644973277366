import { DownloadOutlined, FileTextOutlined } from "@ant-design/icons";
import { Alert, Button, Collapse, Modal, Row, Select, Steps, Table, Tooltip, message } from "antd";
import { DATE_TIME_FORMAT } from "common/constants";
import { camelToWords, downloadFileFromUrl, snakeToCamelCase } from "common/utils";
import { useFetchMeQuery } from "features/auth/authAPI";
import { useFetchAllBackupDatesQuery } from "features/backupDates/backupDatesAPI";
import { useFetchCreditorQuery } from "features/creditors/agencyPortal/creditorsAPI";
import {
  useFetchImportHistoryQuery,
  usePostDataImportCreateMutation,
} from "features/dataImport/agencyPortal/dataImportAPI";
import AccountUploadParamsForm from "features/dataImport/components/accountUploadParamsForm";
import { useFetchProductOfferingsQuery } from "features/productOfferings/productOfferingsAPI";
import { useFetchAllAccountUdfCustomFieldsDromoSchemaQuery } from "features/userDefinedFields/accountUserDefinedFieldsAPI";
import { useFetchAllDebtorUdfCustomFieldsDromoSchemaQuery } from "features/userDefinedFields/agencyPortal/debtorUserDefinedFieldsAPI";
import {
  DromoButton,
  getAccountSchemaFields,
  getMultipleCreditorAccountsSchemaFields,
} from "integrations/dromo";
import accountUpdateSchemaFields from "integrations/dromo/accountUpdateSchemaFields";
import adjustmentSchemaFields from "integrations/dromo/adjustmentSchemaFields";
import debtorUpdateSchemaFields from "integrations/dromo/debtorUpdateSchemaFields";
import deleteAccountSchemaFields from "integrations/dromo/deleteAccountSchemaFields";
import dialerTCNSchemaFields from "integrations/dromo/dialerTCNSchemaFields";
import { convertToDromoCustomField } from "integrations/dromo/dromoUtils";
import lexisNexisSchemaFields from "integrations/dromo/lexisNexisSchemaFields";
import osDialSchemaFields from "integrations/dromo/osDialSchemaFields";
import paymentSchemaFields from "integrations/dromo/paymentSchemaFields";
import moment from "moment-timezone";
import { useMemo, useState } from "react";
import styled from "styled-components";

const { Panel } = Collapse;

const StyledCollapse = styled(Collapse)`
  margin-top: 16px;
  background-color: #f5f5f5;
`;

const StyledPanel = styled(Panel)`
  .ant-collapse-header {
    align-items: center !important;
    padding-bottom: 0 !important;
    padding-left: 8px !important;
  }

  .ant-collapse-content-box {
    padding-top: 0 !important;
  }
`;

const StyledSelect = styled(Select)`
  width: 350px;
  margin-left: 4px;
`;

const StyledGlossarySelect = styled(Select)`
  margin-left: 4px;
`;

const StyledFileTextOutlined = styled(FileTextOutlined)`
  margin-left: 4px;
  cursor: pointer;
  color: #1890ff;
`;

const StyledTable = styled(Table)`
  margin-top: 16px;
`;

const StyledSpan = styled.span`
  margin-left: 8px;
`;

const StyledIndicator = styled.span`
  margin-right: 4px;
`;

export default function DataImport() {
  const [uploadType, setUploadType] = useState(null);
  const [glossaryUploadType, setGlossaryUploadType] = useState("payment_to_agency");

  const [postDataImportCreate] = usePostDataImportCreateMutation();
  const { data: importHistoryData } = useFetchImportHistoryQuery();

  const [additionalData, setAdditionalData] = useState(null);
  const { data: debtorCustomFieldsDromoSchema } =
    useFetchAllDebtorUdfCustomFieldsDromoSchemaQuery();
  const { data: accountCustomFieldsDromoSchema } =
    useFetchAllAccountUdfCustomFieldsDromoSchemaQuery();
  const { data: productOfferings } = useFetchProductOfferingsQuery();
  const [isColumnGlossaryModalOpen, setIsColumnGlossarysModalOpen] = useState(false);

  const { data: selectedCreditor, isFetching } = useFetchCreditorQuery(
    { creditorId: additionalData?.creditorId },
    { skip: !additionalData?.creditorId },
  );
  const [currentStep, setCurrentStep] = useState(0);
  const [errorFile, setErrorFile] = useState(null);
  const { data: me } = useFetchMeQuery();
  const { data: backupDates } = useFetchAllBackupDatesQuery();

  const isAccountUploadType = ["account_commercial", "account_consumer"].includes(uploadType);
  const isMultipleClientsAccountUploadType = [
    "account_multiple_clients_consumer",
    "account_multiple_clients_commercial",
  ].includes(uploadType);

  const submitCsv = async (data, { id, filename }) => {
    setErrorFile(null);
    const result = await postDataImportCreate({
      filename,
      uploadId: id,
      type: uploadType,
      ...additionalData,
    });

    const reset = () => {
      setCurrentStep(0);
      setUploadType(null);
      setAdditionalData(null);
    };

    if ("data" in result) {
      if (result.data.errorFile) {
        setErrorFile(result.data.errorFile);
        message.info("Data imported with errors");
      } else if (result.data.error) {
        message.error(result.data.error);
      } else {
        message.success("Data imported successfully");
        reset();
      }
    }

    if ("error" in result) {
      message.error("Failed to import data");
    }
  };

  const onTypeSelect = (value) => {
    setUploadType(value);
    setGlossaryUploadType(value);
    setCurrentStep(1);
  };

  const onAdditionalDataSubmit = (data) => {
    setAdditionalData(data);
    setCurrentStep(2);
  };

  const isValidAccountUpload = isAccountUploadType
    ? additionalData?.creditorId && additionalData?.cbrClassCode
    : true;

  const isDromoButton = me && uploadType && isValidAccountUpload;

  const unassignedAccountCustomFieldKeys = useMemo(() => {
    if (!selectedCreditor || !accountCustomFieldsDromoSchema) {
      return new Set();
    }
    const result = new Set(accountCustomFieldsDromoSchema.map((field) => field.key) ?? []);
    selectedCreditor.accountCustomFieldsDromoSchema?.forEach((field) => {
      result.delete(field.key);
    });
    return result;
  }, [selectedCreditor, accountCustomFieldsDromoSchema]);

  const matchingStepHelpText = useMemo(() => {
    if (!selectedCreditor) return;
    if (isAccountUploadType && unassignedAccountCustomFieldKeys.size > 0) {
      return `<b>NOTE</b>:<br />To successfully match a column header to an Account User-Defined Field (UDF), the UDF must be properly attributed to the selected client(${selectedCreditor?.name}). <br />To do this, edit the client record and attribute desired UDFs.`;
    }
  }, [selectedCreditor, isAccountUploadType, unassignedAccountCustomFieldKeys]);

  const importTypes = useMemo(() => {
    const allAccountCustomFieldsSchema = (accountCustomFieldsDromoSchema ?? [])
      // if a field is not assigned to the selected creditor, we mark it as not required. (We still want to show them)
      .map((field) => ({
        ...field,
        isRequired: !unassignedAccountCustomFieldKeys.has(field.key) && field.isRequired,
      }))
      .map(convertToDromoCustomField)
      .map((field) => ({
        ...field,
        label: `${field.label} - Account UDF`,
      }));
    // Same fields as allAccountCustomFieldsSchema, but we append information to label when a field not assigned to the selected creditor
    const creditorAccountCustomFieldsSchema = (allAccountCustomFieldsSchema ?? []).map((field) => ({
      ...field,
      label: `${field.label}${
        unassignedAccountCustomFieldKeys.has(field.key) ? " (NOT ATTRIBUTED TO CLIENT)" : ""
      }`,
    }));
    const debtorCustomFieldsSchema = (debtorCustomFieldsDromoSchema ?? [])
      .map(convertToDromoCustomField)
      .map((field) => ({
        ...field,
        label: `${field.label} - Debtor UDF`,
      }));
    const backupDatesRequired =
      backupDates?.filter((item) => item.isRequired).map((item) => item.systemDateField) ?? [];
    return [
      {
        label: "Upload Payments to Agency",
        value: "payment_to_agency",
        schema: paymentSchemaFields,
      },
      {
        label: "Upload Payments to Creditor",
        value: "payment_to_creditor",
        schema: paymentSchemaFields,
      },
      {
        label: "Upload Adjustments",
        value: "adjustment",
        schema: adjustmentSchemaFields,
      },
      {
        label: "Upload Account - Consumer",
        value: "account_consumer",
        schema: [
          ...getAccountSchemaFields("consumer", backupDatesRequired),
          ...creditorAccountCustomFieldsSchema,
          ...debtorCustomFieldsSchema,
        ],
      },
      {
        label: "Upload Account - Commercial",
        value: "account_commercial",
        schema: [
          ...getAccountSchemaFields("commercial", backupDatesRequired),
          ...creditorAccountCustomFieldsSchema,
          ...debtorCustomFieldsSchema,
        ],
      },
      {
        label: "Upload Multiple Clients Account - Consumer",
        value: "account_multiple_clients_consumer",
        schema: [
          ...getMultipleCreditorAccountsSchemaFields(
            {
              accountType: "consumer",
              productOfferings,
            },
            backupDatesRequired,
          ),
          ...allAccountCustomFieldsSchema,
          ...debtorCustomFieldsSchema,
        ],
      },
      {
        label: "Upload Multiple Clients Account - Commercial",
        value: "account_multiple_clients_commercial",
        schema: [
          ...getMultipleCreditorAccountsSchemaFields(
            {
              accountType: "commercial",
              productOfferings,
            },
            backupDatesRequired,
          ),
          ...allAccountCustomFieldsSchema,
          ...debtorCustomFieldsSchema,
        ],
      },
      {
        label: "Delete Accounts",
        value: "account_delete",
        schema: deleteAccountSchemaFields,
      },
      {
        label: "Delete Accounts Without Payments",
        value: "account_delete_without_payment",
        schema: deleteAccountSchemaFields,
      },
      {
        label: "Update Accounts",
        value: "account_update",
        schema: [
          ...accountUpdateSchemaFields,
          ...(accountCustomFieldsDromoSchema ?? [])
            .map((field) => ({
              ...field,
              isRequired: false, // We don't want to require custom fields for account update
            }))
            .map(convertToDromoCustomField)
            .map((field) => ({
              ...field,
              label: `${field.label} - Account UDF`,
            })),
        ],
      },
      {
        label: "Debtor Update",
        value: "debtor_update",
        schema: debtorUpdateSchemaFields,
      },
      {
        label: "LexisNexis Phone Import",
        value: "lexis_nexis",
        schema: lexisNexisSchemaFields,
      },
      {
        label: "TCN Dialer Import",
        value: "dialer_import_tcn",
        schema: dialerTCNSchemaFields,
      },
      {
        label: "OSDial Import",
        value: "dialer_import_osdial",
        schema: osDialSchemaFields,
      },
    ];
  }, [
    accountCustomFieldsDromoSchema,
    debtorCustomFieldsDromoSchema,
    productOfferings,
    unassignedAccountCustomFieldKeys,
    backupDates,
  ]);

  const steps = [
    {
      title: "Choose Action",
      description: (
        <StyledSelect
          popupMatchSelectWidth={false}
          placeholder="Select one..."
          value={uploadType}
          onSelect={onTypeSelect}
          options={importTypes.map((importType) => ({
            label: importType.label,
            value: importType.value,
          }))}
          // options={constants?.thirdPartyImportTypes.map((type) => ({
          //   label: type.display,
          //   value: type.value,
          // }))}
        />
      ),
    },
    (isAccountUploadType || isMultipleClientsAccountUploadType) && {
      title: isAccountUploadType ? "Select Client" : "Select Parent Client",
      description: (
        <AccountUploadParamsForm
          onSubmit={onAdditionalDataSubmit}
          isFetching={isFetching}
          isMultipleClientsAccountUploadType={isMultipleClientsAccountUploadType}
        />
      ),
    },
    {
      title: (
        <>
          Upload File
          <Tooltip title="See Columns Glossary">
            <StyledFileTextOutlined onClick={() => setIsColumnGlossarysModalOpen(true)} />
          </Tooltip>
        </>
      ),
      description: isDromoButton && (
        <DromoButton
          fields={importTypes.find((type) => type.value === uploadType).schema}
          text="Click to Upload"
          onResults={submitCsv}
          importIdentifier={`accounts-agency:${me.agencyId}-type:${uploadType}`}
          matchingStepHelpText={matchingStepHelpText}
        />
      ),
    },
  ];

  const columns = [
    {
      title: "File Name",
      dataIndex: "filename",
      key: "filename",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (type) => camelToWords(snakeToCamelCase(type)),
    },
    {
      title: "Upload Time",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => moment(date).format(DATE_TIME_FORMAT),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status, record) => {
        return (
          <span>
            {camelToWords(snakeToCamelCase(status))}
            {record.errorFile && (
              <Button
                icon={<DownloadOutlined />}
                type="link"
                onClick={() => downloadFileFromUrl(record.errorFile)}
              >
                Download Error File
              </Button>
            )}
          </span>
        );
      },
    },
  ];

  const handleCancel = () => {
    setIsColumnGlossarysModalOpen(false);
  };

  return (
    <div>
      <Steps direction="vertical" current={currentStep} items={steps} />
      {errorFile && (
        <Alert
          type="warning"
          message={
            <span>
              Data may be partially uploaded. Click <a href={errorFile}>here</a> to download the
              list of errors for rows that were not uploaded
            </span>
          }
        />
      )}
      <StyledCollapse defaultActiveKey="history" bordered={false}>
        <StyledPanel header={<h3>Import History</h3>} key="history">
          <Table
            scroll={{ x: "max-content" }}
            columns={columns}
            bordered
            dataSource={importHistoryData}
          />
        </StyledPanel>
      </StyledCollapse>
      {isColumnGlossaryModalOpen && (
        <Modal
          title="Columns Glossary"
          open={isColumnGlossaryModalOpen}
          footer={null}
          onCancel={handleCancel}
        >
          <Row align="middle">
            <span>Upload Type: </span>
            <StyledGlossarySelect
              popupMatchSelectWidth={false}
              placeholder="Select Upload Type..."
              value={glossaryUploadType}
              // @ts-ignore
              onChange={(value) => setGlossaryUploadType(value)}
              options={importTypes.map((importType) => ({
                label: importType.label,
                value: importType.value,
              }))}
            />
          </Row>
          <StyledTable
            size="small"
            pagination={{
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: [10, 20, 30, 100],
            }}
            columns={[
              {
                title: (
                  <div>
                    <span>Available Columns</span>
                    <StyledSpan>(* indicates a required field)</StyledSpan>
                  </div>
                ),
                dataIndex: "label",
                key: "label",
                render: (label, record) => (
                  <span>
                    {/* @ts-ignore */}
                    {record.validators?.[0].validate === "required" && (
                      <StyledIndicator>*</StyledIndicator>
                    )}
                    {label}
                  </span>
                ),
              },
            ]}
            bordered
            dataSource={importTypes.find((type) => type.value === glossaryUploadType).schema ?? []}
          />
        </Modal>
      )}
    </div>
  );
}
