import { Row } from "antd";
import TableFilter from "components/tableFilter/tableFilter";
import { useFetchFeePlansQuery } from "features/feePlans/feePlansAPI";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import PaymentAllocationTable from "pages/agencyPortal/payments/paymentAllocationTable";
import { useState } from "react";

const initialFilterState = {
  scheduledDate: null,
  status: {
    include: [],
    exclude: [],
  },
  isSettlement: null,
  paymentAllocationAmount: {
    min: null,
    max: null,
  },
  creditorFeePlan: {
    include: [],
    exclude: [],
  },
  forwardingEntityFeePlan: {
    include: [],
    exclude: [],
  },
};

export default function PaymentAllocationsTab() {
  const { data: constants } = useFetchBackendConstantsQuery();
  const { data: feePlans } = useFetchFeePlansQuery();
  const [filterStates, setFilterStates] = useState(initialFilterState);

  function setFilterProperty(name, value) {
    setFilterStates((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  }

  // This is the structure of the filters that will be displayed on the page
  const filteredStructure = [
    {
      title: "Scheduled Date",
      name: "scheduledDate",
      type: "date",
      onChange: (event) => setFilterProperty("scheduledDate", event),
    },
    {
      title: "Status",
      name: "status",
      type: "include/exclude",
      include: {
        mode: "multiple",
        onChange: (event) => {
          setFilterProperty("status", { ...filterStates.status, include: event });
        },
        options: constants?.paymentStatus?.map((d) => ({
          label: d.display,
          value: d.value,
        })),
        placeholder: "Select Status...",
      },
      exclude: {
        mode: "multiple",
        onChange: (event) => {
          setFilterProperty("status", { ...filterStates.status, exclude: event });
        },
        options: constants?.paymentStatus?.map((d) => ({
          label: d.display,
          value: d.value,
        })),
        placeholder: "Select Status...",
      },
    },
    {
      title: "Settlement",
      name: "isSettlement",
      type: "checkbox-option",
      options: [
        { label: "Settled", value: "settled" },
        { label: "Not Settled", value: "not_settled" },
      ],
      onChange: (event) =>
        setFilterProperty(
          "isSettlement",
          event.target.value && event.target.checked ? event.target.value : null,
        ),
      value: filterStates.isSettlement,
    },
    {
      title: "Payment Allocation Amount",
      name: "paymentAllocation",
      type: "min/max",
      min: {
        prefix: "$",
        step: "0.01",
        min: "0",
        onChange: (event) =>
          setFilterProperty("paymentAllocationAmount", {
            ...filterStates.paymentAllocationAmount,
            min: event,
          }),
      },
      max: {
        prefix: "$",
        step: "0.01",
        min: "0",
        onChange: (event) =>
          setFilterProperty("paymentAllocationAmount", {
            ...filterStates.paymentAllocationAmount,
            max: event,
          }),
      },
    },
    {
      title: "Creditor Fee Plan",
      name: "creditorFeePlan",
      type: "include/exclude",
      include: {
        mode: "multiple",
        onChange: (event) => {
          setFilterProperty("creditorFeePlan", { ...filterStates.creditorFeePlan, include: event });
        },
        options: feePlans?.map((d) => ({
          label: d.name,
          value: d.id,
        })),
        placeholder: "Select Fee Plan...",
      },
      exclude: {
        mode: "multiple",
        onChange: (event) => {
          setFilterProperty("creditorFeePlan", { ...filterStates.creditorFeePlan, exclude: event });
        },
        options: feePlans?.map((d) => ({
          label: d.name,
          value: d.id,
        })),
        placeholder: "Select Fee Plan...",
      },
    },
    {
      title: "Forwarding Entity Fee Plan",
      name: "forwardingEntityFeePlan",
      type: "include/exclude",
      include: {
        mode: "multiple",
        onChange: (event) => {
          setFilterProperty("forwardingEntityFeePlan", {
            ...filterStates.forwardingEntityFeePlan,
            include: event,
          });
        },
        options: feePlans?.map((d) => ({
          label: d.name,
          value: d.id,
        })),
        placeholder: "Select Fee Plan...",
      },
      exclude: {
        mode: "multiple",
        onChange: (event) => {
          setFilterProperty("forwardingEntityFeePlan", {
            ...filterStates.forwardingEntityFeePlan,
            exclude: event,
          });
        },
        options: feePlans?.map((d) => ({
          label: d.name,
          value: d.id,
        })),
        placeholder: "Select Fee Plan...",
      },
    },
  ];

  /**
   * Convert the filter states to query params
   * @returns {Object} queryParams - The query parameters
   * @returns {string} queryParams.scheduledDateStart - The start date for scheduling
   * @returns {string} queryParams.scheduledDateEnd - The end date for scheduling
   * @returns {string[]} queryParams.includeStatusCodes - The status codes to include
   * @returns {string[]} queryParams.excludeStatusCodes - The status codes to exclude
   * @returns {boolean} queryParams.isSettlement - Whether it is a settlement
   * @returns {string} queryParams.minTotalAmount - The minimum total amount
   * @returns {string} queryParams.maxTotalAmount - The maximum total amount
   * @returns {string[]} queryParams.includeCreditorFeePlanIds - The creditor fee plan IDs to include
   * @returns {string[]} queryParams.excludeCreditorFeePlanIds - The creditor fee plan IDs to exclude
   * @returns {string[]} queryParams.includeForwardingEntityFeePlanIds - The forwarding entity fee plan IDs to include
   * @returns {string[]} queryParams.excludeForwardingEntityFeePlanIds - The forwarding entity fee plan IDs to exclude
   */
  function convertStateToQueryParams() {
    const queryParams = {};
    if (filterStates.scheduledDate) {
      const [scheduledDateStart, scheduledDateEnd] = filterStates.scheduledDate;
      if (scheduledDateStart) {
        queryParams.scheduledDateStart = scheduledDateStart;
      }
      if (scheduledDateEnd) {
        queryParams.scheduledDateEnd = scheduledDateEnd;
      }
    }

    if (filterStates.status.include.length > 0) {
      queryParams.includeStatusCodes = filterStates.status.include;
    }
    if (filterStates.status.exclude.length > 0) {
      queryParams.excludeStatusCodes = filterStates.status.exclude;
    }
    if (filterStates.isSettlement) {
      queryParams.isSettlement = filterStates.isSettlement === "settled";
    }

    if (filterStates.paymentAllocationAmount.min) {
      queryParams.minTotalAmount = filterStates.paymentAllocationAmount.min;
    }

    if (filterStates.paymentAllocationAmount.max) {
      queryParams.maxTotalAmount = filterStates.paymentAllocationAmount.max;
    }

    if (filterStates.creditorFeePlan.include.length > 0) {
      queryParams.includeCreditorFeePlanIds = filterStates.creditorFeePlan.include;
    }
    if (filterStates.creditorFeePlan.exclude.length > 0) {
      queryParams.excludeCreditorFeePlanIds = filterStates.creditorFeePlan.exclude;
    }

    if (filterStates.forwardingEntityFeePlan.include.length > 0) {
      queryParams.includeForwardingEntityFeePlanIds = filterStates.forwardingEntityFeePlan.include;
    }
    if (filterStates.forwardingEntityFeePlan.exclude.length > 0) {
      queryParams.excludeForwardingEntityFeePlanIds = filterStates.forwardingEntityFeePlan.exclude;
    }

    return queryParams;
  }

  function resetFilters() {
    setFilterStates(initialFilterState);
  }
  return (
    <Row wrap={false}>
      <TableFilter filteredStructure={filteredStructure} onReset={() => resetFilters()} />
      <PaymentAllocationTable filterParams={convertStateToQueryParams()} />
    </Row>
  );
}
