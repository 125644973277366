import { MoreOutlined } from "@ant-design/icons";
import { Button, Dropdown, Space } from "antd";
import { useUpdatePaymentAllocationsFeePlanMutation } from "features/payments/paymentsAllocations";
import PaymentAllocationFeePlanModal from "pages/agencyPortal/payments/paymentAllocationFeePlanModal";
import { useState } from "react";

export default function PaymentAllocationBulkActions({ records = [] }) {
  const [isCreditorModalVisible, setCreditorModalVisible] = useState(false);
  const [isForwardingEntityModalVisible, setForwardingEntityModalVisible] = useState(false);
  const [updateFeePlans] = useUpdatePaymentAllocationsFeePlanMutation();
  const items = [
    {
      key: "updateCreditorFeePlan",
      label: "Update Creditor Fee Plan",
      disabled: records.length === 0,
    },
    {
      key: "updateForwardingEntityFeePlan",
      label: "Update Forwarding Entity Fee Plan",
      disabled: records.length === 0,
    },
  ];

  const actionHandlers = {
    updateCreditorFeePlan: () => {
      setCreditorModalVisible(true);
    },
    updateForwardingEntityFeePlan: () => {
      setForwardingEntityModalVisible(true);
    },
  };
  return (
    <>
      <Space size="small">
        <Dropdown
          menu={{
            // @ts-ignore
            items,
            onClick: (clickHandlerProp) => actionHandlers[clickHandlerProp.key](),
          }}
          trigger={["click"]}
        >
          <Button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            type="text"
          >
            <MoreOutlined />
          </Button>
        </Dropdown>
      </Space>
      {isCreditorModalVisible && (
        <PaymentAllocationFeePlanModal
          open={isCreditorModalVisible}
          onOk={(data) => {
            updateFeePlans({
              ...data,
              updateCreditorFeePlan: true,
              paymentAllocationIDs: records.map((record) => record.id),
            });
            setCreditorModalVisible(false);
          }}
          onCancel={() => setCreditorModalVisible(false)}
        />
      )}
      {isForwardingEntityModalVisible && (
        <PaymentAllocationFeePlanModal
          open={isForwardingEntityModalVisible}
          onOk={(data) => {
            updateFeePlans({
              ...data,
              updateForwardingEntityFeePlan: true,
              paymentAllocationIDs: records.map((record) => record.id),
            });
            setForwardingEntityModalVisible(false);
          }}
          onCancel={() => setForwardingEntityModalVisible(false)}
        />
      )}
    </>
  );
}
