import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input, Popconfirm, Row, Select, Space, Tag, message } from "antd";
import { DATE_TIME_FORMAT } from "common/constants";
import { formatError } from "common/redux/middleware/queryErrorLogger";
import { camelToWords, formatDate, snakeToCamelCase, toTitleCase } from "common/utils";
import { AktDatePicker } from "components/aktDatePicker";
import { ConsentTag } from "components/consentTag";
import { DisplayComponent, DisplayPhoneNumber } from "components/formItems";
import { useUserType } from "features/auth";
import {
  useCreateDebtorPhoneNumberMutation,
  useDeleteDebtorPhoneNumberMutation,
  useUpdateDebtorPhoneNumberMutation,
} from "features/debtorSider/agencyPortal/debtorSiderAPI";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import { PERMISSIONS } from "features/permissions";
import useAuthorizations from "features/permissions/hooks/useAuthorizations";
import moment from "moment-timezone";
import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const StyledCard = styled.div`
  margin-top: 0px;
  margin-left: 8px;
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledHeaderText = styled.h4`;
`;

const StyledTag = styled(Tag)`
  margin-right: 0;
  font-weight: normal;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

export function DebtorPhoneInfoCard({
  phone = undefined,
  isNew = undefined,
  onCreate = undefined,
  onCancel = undefined,
  title = undefined,
  profileType = "consumer",
}) {
  const { isAgencyUserType } = useUserType();
  const { debtorId } = useParams();
  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState(isNew || false);
  const { data: constants } = useFetchBackendConstantsQuery();
  const [updateDebtorPhoneNumber, { isLoading: isUpdateLoading }] =
    useUpdateDebtorPhoneNumberMutation();
  const [createDebtorPhoneNumber, { isLoading: isCreateLoading }] =
    useCreateDebtorPhoneNumberMutation();
  const [deleteDebtorPhoneNumber, { isLoading: isDeleteLoading }] =
    useDeleteDebtorPhoneNumberMutation();

  const makeOnConsentStatusChangeCallback = useCallback(
    (statusChangeDateFieldName) => {
      return (value) => {
        if (
          value === "consent" &&
          // The intention here isis: if the user touched the value manually
          // (excluding when using the clearing buttn)
          // we don't want to mess with user selected values.
          (!form.isFieldTouched(statusChangeDateFieldName) ||
            !form.getFieldValue(statusChangeDateFieldName))
        ) {
          form.setFields([
            // setFieldValue would set touched to true, use this to avoid that.
            {
              name: statusChangeDateFieldName,
              value: formatDate(moment().endOf("day")),
              touched: false,
            },
          ]);
        }
      };
    },
    [form],
  );

  const onPermissionToCallStatusChange = useMemo(() => {
    return makeOnConsentStatusChangeCallback("permissionToCallStatusChangeDate");
  }, [makeOnConsentStatusChangeCallback]);
  const onPermissionToTextStatusChange = useMemo(() => {
    return makeOnConsentStatusChangeCallback("permissionToTextStatusChangeDate");
  }, [makeOnConsentStatusChangeCallback]);

  const {
    data: {
      [PERMISSIONS.DEBTOR_PHONE_NUMBER__CREATE]: isAddDebtorPhoneNumberAuthorized,
      [PERMISSIONS.DEBTOR_PHONE_NUMBER__UPDATE]: isUpdateDebtorPhoneNumberAuthorized,
      [PERMISSIONS.DEBTOR_PHONE_NUMBER__DELETE]: isDeleteDebtorPhoneNumberAuthorized,
    },
  } = useAuthorizations();
  const editableDataSourceTypeValues = constants?.editableDataSourceTypes?.map(
    ({ value }) => value,
  );

  const deletePhoneNumber = async () => {
    const result = await deleteDebtorPhoneNumber({ debtorId, phoneNumberId: phone.id });
    if ("data" in result) {
      message.success("Phone number deleted successfully!");
    }
  };
  const toggleIsEditing = async () => {
    if (isEditing) {
      let values;
      try {
        values = await form.validateFields();
      } catch (e) {
        return null; // Form will handle error messages
      }

      const action = isNew
        ? createDebtorPhoneNumber({ debtorId, ...values })
        : updateDebtorPhoneNumber({
            debtorId,
            phoneNumberId: phone.id,
            ...values,
          });
      const result = await action;

      if ("data" in result) {
        message.success("Saved successfully!");
        if (isNew) onCreate();
      }

      if ("error" in result) {
        form.setFields(formatError(result.error));
        message.error("Could not update phone info");
        return;
      }
      setIsEditing(false);
    } else {
      setIsEditing(true);
      form.setFieldsValue(phone);
    }
  };

  const onPrimaryCancel = () => {
    setIsEditing(false);
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 20 },
      sm: { span: 13 },
    },
  };

  const consumerPhoneTypes = constants?.phoneTypes.map(({ display, value }) => ({
    value,
    label: display,
  }));
  const commercialPhoneTypes = constants?.phoneTypes
    .filter(({ value }) => value !== "home")
    .map(({ display, value }) => ({
      value,
      label: display,
    }));

  const displayPermissionToCallStatusChangeDate = (
    <DisplayComponent
      isEditing={isEditing}
      slice={phone}
      label="Phone Consent Change Date"
      name="permissionToCallStatusChangeDate"
      outputFormatter={formatDate}
    >
      <AktDatePicker />
    </DisplayComponent>
  );

  const displayPermissionToTextStatusChangeDate = (
    <DisplayComponent
      isEditing={isEditing}
      slice={phone}
      label="SMS Consent Change Date"
      name="permissionToTextStatusChangeDate"
      outputFormatter={formatDate}
    >
      <AktDatePicker />
    </DisplayComponent>
  );

  return (
    <StyledCard>
      <StyledHeader>
        <StyledHeaderText>
          <Space>
            {isNew ? "New Phone Number" : title}
            {phone?.isPrimary && !isEditing && <StyledTag color="gold">PRIMARY</StyledTag>}
            {!isNew && !isEditing && (
              <>
                {["consent", "no_consent"].includes(phone?.permissionToCallStatus) && (
                  <ConsentTag isConsent={phone?.permissionToCallStatus === "consent"}>
                    Phone
                  </ConsentTag>
                )}
                {["consent", "no_consent"].includes(phone?.permissionToTextStatus) && (
                  <ConsentTag isConsent={phone?.permissionToTextStatus === "consent"}>
                    SMS
                  </ConsentTag>
                )}
              </>
            )}
          </Space>
        </StyledHeaderText>
        {!isEditing &&
          isAgencyUserType &&
          (isUpdateDebtorPhoneNumberAuthorized || isDeleteDebtorPhoneNumberAuthorized) && (
            <Row>
              {isUpdateDebtorPhoneNumberAuthorized && (
                <Button type="link" icon={<EditOutlined />} onClick={toggleIsEditing} />
              )}
              {isDeleteDebtorPhoneNumberAuthorized && (
                <Popconfirm
                  placement="topRight"
                  okText="Yes"
                  title="Are you sure you want to delete this phone number?"
                  onConfirm={deletePhoneNumber}
                >
                  <Button loading={isDeleteLoading} type="link" icon={<DeleteOutlined />} />
                </Popconfirm>
              )}
            </Row>
          )}
      </StyledHeader>
      {isEditing ? (
        <>
          <Form
            labelAlign="left"
            form={form}
            {...formItemLayout}
            initialValues={{
              dataSource: "",
              permissionToCallStatus: "unknown",
              permissionToTextStatus: "unknown",
              status: "",
            }}
          >
            <DisplayComponent
              isEditing={isEditing}
              slice={phone}
              label="Primary"
              name="isPrimary"
              valuePropName="checked"
              hidden={phone?.isPrimary}
            >
              <Checkbox />
            </DisplayComponent>
            <DisplayComponent
              isEditing={isEditing}
              slice={phone}
              label="Phone"
              name="number"
              placeholder="XXX-XXX-XXXX"
              rules={[{ required: true, message: "Please enter a valid phone" }]}
            />
            <DisplayComponent
              isEditing={isEditing}
              slice={phone}
              label="Type"
              name="type"
              rules={[{ required: true }]}
            >
              <Select
                options={profileType === "consumer" ? consumerPhoneTypes : commercialPhoneTypes}
              />
            </DisplayComponent>
            <DisplayComponent
              isEditing={isEditing}
              slice={phone}
              label="Consent For Phone"
              name="permissionToCallStatus"
              rules={[{ required: true, message: "Please select a consent status" }]}
            >
              <Select
                onChange={onPermissionToCallStatusChange}
                options={constants?.contactPermissionStatuses?.map((each) => ({
                  label: each.display,
                  value: each.value,
                }))}
              />
            </DisplayComponent>
            {displayPermissionToCallStatusChangeDate}
            <DisplayComponent
              isEditing={isEditing}
              slice={phone}
              label="Consent For SMS"
              name="permissionToTextStatus"
              rules={[{ required: true, message: "Please select a consent status" }]}
            >
              <Select
                onChange={onPermissionToTextStatusChange}
                options={constants?.contactPermissionStatuses?.map((each) => ({
                  label: each.display,
                  value: each.value,
                }))}
              />
            </DisplayComponent>
            {displayPermissionToTextStatusChangeDate}
            <DisplayComponent isEditing={isEditing} slice={phone} label="Status" name="status">
              <Select
                options={constants?.phoneStatuses?.map((each) => ({
                  label: each.display,
                  value: each.value,
                }))}
              />
            </DisplayComponent>
            <DisplayComponent isEditing={isEditing} slice={phone} label="Notes" name="notes">
              <Input.TextArea placeholder="Add Notes" />
            </DisplayComponent>
            {/* Only allow setting of data source if no data source is set, or it is editable (i.e. present in the editableDataSourceTypes) */}
            {(!phone?.dataSource || editableDataSourceTypeValues.includes(phone?.dataSource)) && (
              <DisplayComponent
                isEditing={isEditing}
                slice={phone}
                label="Data Source"
                name="dataSource"
              >
                <Select
                  options={constants?.editableDataSourceTypes?.map(({ display, value }) => ({
                    value,
                    label: display,
                  }))}
                />
              </DisplayComponent>
            )}
          </Form>
          <StyledButtonContainer>
            <Space>
              <Button onClick={isNew ? onCancel : onPrimaryCancel}>Cancel</Button>
              {(isAddDebtorPhoneNumberAuthorized || isUpdateDebtorPhoneNumberAuthorized) && (
                <Button
                  loading={isCreateLoading || isUpdateLoading}
                  onClick={toggleIsEditing}
                  type="primary"
                >
                  Save
                </Button>
              )}
            </Space>
          </StyledButtonContainer>
        </>
      ) : (
        <StyledDiv>
          <DisplayPhoneNumber
            notes={phone.notes}
            isValid={phone.type !== "invalid"}
            number={phone.number}
            type={toTitleCase({ str: phone.type, delimiter: "_" })}
          />
          <DisplayComponent
            isEditing={isEditing}
            slice={phone}
            label="Status"
            name="status"
            outputFormatter={(value) => camelToWords(snakeToCamelCase(value))}
          />
          <DisplayComponent
            isEditing={false}
            slice={phone}
            label="Data Source"
            name="dataSource"
            outputFormatter={(value) => camelToWords(snakeToCamelCase(value))}
          />
          <DisplayComponent
            isEditing={false}
            slice={phone}
            label="Created At"
            name="createdAt"
            outputFormatter={(value) => moment(value).format(DATE_TIME_FORMAT)}
          />
          {displayPermissionToCallStatusChangeDate}
          {displayPermissionToTextStatusChangeDate}
        </StyledDiv>
      )}
    </StyledCard>
  );
}
