import { Tabs } from "antd";
import Content from "layouts/agencyPortal/dashboard/content";
import PageLayout from "layouts/agencyPortal/dashboard/pageLayout";
import PaymentAllocationsTab from "pages/agencyPortal/payments/paymentAllocationsTab";
import PaymentsListTab from "pages/agencyPortal/payments/paymentListTab";

const items = [
  {
    key: "1",
    label: "Payments",
    children: <PaymentsListTab />,
  },
  {
    key: "2",
    label: "Payment Allocations",
    children: <PaymentAllocationsTab />,
  },
];

function PaymentsPage() {
  return (
    <PageLayout>
      <Content>
        <Tabs defaultActiveKey="1" items={items} />
      </Content>
    </PageLayout>
  );
}

export default PaymentsPage;
