import { getBackupRequiredValidator } from "integrations/dromo/accountSchemaUtils";
import { DROMO_DATE_FORMAT } from "integrations/dromo/dromoFormats";

const usStates = [
  { label: "AL", value: "AL" },
  { label: "AK", value: "AK" },
  { label: "AZ", value: "AZ" },
  { label: "AE", value: "AE" },
  { label: "AP", value: "AP" },
  { label: "AR", value: "AR" },
  { label: "CA", value: "CA" },
  { label: "CO", value: "CO" },
  { label: "CT", value: "CT" },
  { label: "DC", value: "DC" },
  { label: "DE", value: "DE" },
  { label: "FL", value: "FL" },
  { label: "GA", value: "GA" },
  { label: "GU", value: "GU" },
  { label: "HI", value: "HI" },
  { label: "ID", value: "ID" },
  { label: "IL", value: "IL" },
  { label: "IN", value: "IN" },
  { label: "IA", value: "IA" },
  { label: "KS", value: "KS" },
  { label: "KY", value: "KY" },
  { label: "LA", value: "LA" },
  { label: "ME", value: "ME" },
  { label: "MD", value: "MD" },
  { label: "MA", value: "MA" },
  { label: "MI", value: "MI" },
  { label: "MN", value: "MN" },
  { label: "MS", value: "MS" },
  { label: "MO", value: "MO" },
  { label: "MT", value: "MT" },
  { label: "NE", value: "NE" },
  { label: "NV", value: "NV" },
  { label: "NH", value: "NH" },
  { label: "NJ", value: "NJ" },
  { label: "NM", value: "NM" },
  { label: "NY", value: "NY" },
  { label: "NC", value: "NC" },
  { label: "ND", value: "ND" },
  { label: "MP", value: "MP" },
  { label: "OH", value: "OH" },
  { label: "OK", value: "OK" },
  { label: "OR", value: "OR" },
  { label: "PA", value: "PA" },
  { label: "PR", value: "PR" },
  { label: "RI", value: "RI" },
  { label: "SC", value: "SC" },
  { label: "SD", value: "SD" },
  { label: "TN", value: "TN" },
  { label: "TX", value: "TX" },
  { label: "UT", value: "UT" },
  { label: "VT", value: "VT" },
  { label: "VA", value: "VA" },
  { label: "VI", value: "VI" },
  { label: "WA", value: "WA" },
  { label: "WV", value: "WV" },
  { label: "WI", value: "WI" },
  { label: "WY", value: "WY" },
];

const addressType = [
  { label: "Mail", value: "mail" },
  { label: "Home", value: "home" },
  { label: "Business", value: "business" },
  { label: "Mail Return", value: "mail_return" },
  { label: "Billing", value: "billing" },
  { label: "Service", value: "service" },
  { label: "Intelligent Contacts", value: "intelligent_contacts" },
  { label: "Other", value: "other" },
  { label: "Invalid", value: "invalid" },
];

const emailType = [
  { label: "Personal", value: "personal" },
  { label: "Work", value: "work" },
  { label: "Intelligent Contacts", value: "intelligent_contacts" },
  { label: "Other", value: "other" },
  { label: "Invalid", value: "invalid" },
];

const emailConsentStatusType = [
  { label: "Unknown", value: "unknown" },
  { label: "Consent", value: "consent" },
  { label: "No Consent", value: "no_consent" },
];

const emailStatusType = [
  { label: "None", value: "" },
  { label: "Active", value: "active" },
  { label: "Inactive", value: "inactive" },
  { label: "Undeliverable", value: "undeliverable" },
];

const phoneType = [
  { label: "Work", value: "work" },
  { label: "Home", value: "home" },
  { label: "Cell", value: "cell" },
  { label: "Fax", value: "fax" },
  { label: "Intelligent Contacts", value: "intelligent_contacts" },
  { label: "Other", value: "other" },
  { label: "Invalid", value: "invalid" },
];

const consumerContactRelationType = [
  { label: "Spouse", value: "spouse" },
  { label: "Partner", value: "partner" },
  { label: "Sibling", value: "sibling" },
  { label: "Parent", value: "parent" },
  { label: "Guardian", value: "guardian" },
  { label: "Family", value: "family" },
  { label: "Attorney", value: "attorney" },
  { label: "Employer", value: "employer" },
  { label: "Friend", value: "friend" },
  { label: "Neighbor", value: "neighbor" },
  { label: "Other", value: "other" },
];

const commercialContactRelationType = [
  { label: "Attorney", value: "attorney" },
  { label: "Owner", value: "owner" },
  { label: "Accountant", value: "accountant" },
  { label: "Manager", value: "manager" },
  { label: "Other", value: "other" },
];

const consumerDebtorFields = [
  {
    key: "debtor_contact_1_relationship",
    label: "Debtor Contact 1 Relationship",
    type: "select",
    selectOptions: consumerContactRelationType,
  },
  {
    key: "debtor_contact_2_relationship",
    label: "Debtor Contact 2 Relationship",
    type: "select",
    selectOptions: consumerContactRelationType,
  },
  {
    key: "debtor_contact_3_relationship",
    label: "Debtor Contact 3 Relationship",
    type: "select",
    selectOptions: consumerContactRelationType,
  },
  {
    key: "debtor_first_name",
    label: "Debtor First Name",
    type: "string",
    validators: [{ validate: "required" }],
  },
  {
    key: "debtor_middle_name",
    label: "Debtor Middle Name",
    type: "string",
  },
  {
    key: "debtor_last_name",
    label: "Debtor Last Name",
    type: "string",
    validators: [{ validate: "required" }],
  },
  {
    key: "debtor_ssn",
    label: "Debtor SSN",
    type: "string",
  },
  {
    key: "debtor_dob",
    label: "Debtor Date Of Birth",
    type: DROMO_DATE_FORMAT,
  },
  {
    key: "debtor_drivers_license_number",
    label: "Debtor Driver's License Number",
    type: "string",
  },
];

const commercialDebtorFields = [
  {
    key: "debtor_contact_1_relationship",
    label: "Debtor Contact 1 Relationship",
    type: "select",
    selectOptions: commercialContactRelationType,
  },
  {
    key: "debtor_contact_2_relationship",
    label: "Debtor Contact 2 Relationship",
    type: "select",
    selectOptions: commercialContactRelationType,
  },
  {
    key: "debtor_contact_3_relationship",
    label: "Debtor Contact 3 Relationship",
    type: "select",
    selectOptions: commercialContactRelationType,
  },
  {
    key: "debtor_company_name",
    label: "Company Name",
    type: "string",
    validators: [{ validate: "required" }],
  },
  {
    key: "debtor_ein",
    label: "Company EIN",
    type: "string",
  },
];

// Define groups of field keys that are required together (i.e. if one is present, all must be present in the group)
const accountRequiredTogetherFields = [
  new Set([
    "debtor_address_1",
    "debtor_address_city",
    "debtor_address_state",
    "debtor_address_zip_code",
    "debtor_address_type",
  ]),
  new Set([
    "debtor_address_2_1",
    "debtor_address_2_city",
    "debtor_address_2_state",
    "debtor_address_2_zip_code",
    "debtor_address_2_type",
  ]),
  new Set([
    "debtor_address_3_1",
    "debtor_address_3_city",
    "debtor_address_3_state",
    "debtor_address_3_zip_code",
    "debtor_address_3_type",
  ]),
  new Set([
    "debtor_contact_1_first_name",
    "debtor_contact_1_last_name",
    "debtor_contact_1_relationship",
  ]),
  new Set([
    "debtor_contact_2_first_name",
    "debtor_contact_2_last_name",
    "debtor_contact_2_relationship",
  ]),
  new Set([
    "debtor_contact_3_first_name",
    "debtor_contact_3_last_name",
    "debtor_contact_3_relationship",
  ]),
  new Set(["debtor_email_1_address", "debtor_email_1_type", "debtor_email_1_consent_status"]),
  new Set(["debtor_email_2_address", "debtor_email_2_type", "debtor_email_2_consent_status"]),
  new Set(["debtor_email_3_address", "debtor_email_3_type", "debtor_email_3_consent_status"]),
];

export default function getAccountSchemaFields(accountType, requiredBackupFields = []) {
  const fields = [
    {
      key: "client_reference_id",
      label: "Client Reference ID",
      type: "string",
    },
    {
      key: "assignee_id",
      label: "Assignee ID",
      type: "number",
    },
    {
      key: "principal",
      label: "Original Principal",
      type: ["number", "usd"],
      validators: [{ validate: "required" }],
    },
    {
      key: "interest",
      label: "Original Interest",
      type: ["number", "usd"],
    },
    {
      key: "total_previous_payment_amount",
      label: "Total Previous Payment Amount",
      type: ["number", "usd"],
    },
    {
      key: "last_payment_date",
      label: "Last Payment Date",
      type: DROMO_DATE_FORMAT,
      validators: getBackupRequiredValidator("last_payment_date", requiredBackupFields),
    },
    {
      key: "interest_rate",
      label: "Interest Rate",
      type: ["number", "percent"],
      validators: [
        { validate: "require_with", fields: ["interest_start_date", "interest_end_date"] },
      ],
      description:
        "Interest rates must either be formatted as a decimal (e.g '0.20'), or contain the percentage sign (e.g '20%')",
    },
    {
      key: "date_of_service",
      label: "Date of Service",
      type: DROMO_DATE_FORMAT,
      validators: getBackupRequiredValidator("date_of_service", requiredBackupFields),
    },
    {
      key: "itemization_date",
      label: "Itemization Date",
      type: DROMO_DATE_FORMAT,
      validators: getBackupRequiredValidator("itemization_date", requiredBackupFields),
    },
    {
      key: "judgment_date",
      label: "Judgement Date",
      type: DROMO_DATE_FORMAT,
    },
    {
      key: "date_of_first_delinquency",
      label: "Date of First Delinquency",
      type: DROMO_DATE_FORMAT,
      validators: getBackupRequiredValidator("date_of_first_delinquency", requiredBackupFields),
    },
    {
      key: "interest_start_date",
      label: "Interest Start Date",
      type: DROMO_DATE_FORMAT,
      validators: getBackupRequiredValidator("interest_start_date", requiredBackupFields, [
        { validate: "require_with", fields: ["interest_rate", "interest_end_date"] },
      ]),
    },
    {
      key: "interest_end_date",
      label: "Interest End Date",
      type: DROMO_DATE_FORMAT,
    },
    {
      key: "last_statement_date",
      label: "Last Statement Date",
      type: DROMO_DATE_FORMAT,
      validators: getBackupRequiredValidator("last_statement_date", requiredBackupFields),
    },
    {
      key: "turnover_date",
      label: "Turnover Date",
      type: DROMO_DATE_FORMAT,
      validators: getBackupRequiredValidator("turnover_date", requiredBackupFields),
    },
    {
      key: "charge_off_date",
      label: "Charge Off Date",
      type: DROMO_DATE_FORMAT,
      validators: getBackupRequiredValidator("charge_off_date", requiredBackupFields),
    },
    ...(accountType === "commercial" ? commercialDebtorFields : consumerDebtorFields),
    {
      key: "is_debtor_address_1_is_primary",
      label: "Is Address 1 Primary",
      type: "checkbox",
    },
    {
      key: "debtor_address_1",
      label: "Debtor Address Line 1",
      type: "string",
    },
    {
      key: "debtor_address_2",
      label: "Debtor Address Line 2",
      type: "string",
    },
    {
      key: "debtor_address_city",
      label: "Debtor Address City",
      type: "string",
    },
    {
      key: "debtor_address_state",
      label: "Debtor Address State",
      type: "select",
      selectOptions: usStates,
    },
    {
      key: "debtor_address_zip_code",
      label: "Debtor Address Zip Code",
      type: "string",
    },
    {
      key: "debtor_address_type",
      label: "Debtor Address Type",
      type: "select",
      selectOptions: addressType,
    },
    {
      key: "is_debtor_address_2_is_primary",
      label: "Is Address 2 Primary",
      type: "checkbox",
    },
    {
      key: "debtor_address_2_1",
      label: "Debtor Address 2 Line 1",
      type: "string",
    },
    {
      key: "debtor_address_2_2",
      label: "Debtor Address 2 Line 2",
      type: "string",
    },
    {
      key: "debtor_address_2_city",
      label: "Debtor Address 2 City",
      type: "string",
    },
    {
      key: "debtor_address_2_state",
      label: "Debtor Address 2 State",
      type: "select",
      selectOptions: usStates,
    },
    {
      key: "debtor_address_2_zip_code",
      label: "Debtor Address 2 Zip Code",
      type: "string",
    },
    {
      key: "debtor_address_2_type",
      label: "Debtor Address 2 Type",
      type: "select",
      selectOptions: addressType,
    },
    {
      key: "is_debtor_address_3_is_primary",
      label: "Is Address 3 Primary",
      type: "checkbox",
    },
    {
      key: "debtor_address_3_1",
      label: "Debtor Address 3 Line 1",
      type: "string",
    },
    {
      key: "debtor_address_3_2",
      label: "Debtor Address 3 Line 2",
      type: "string",
    },
    {
      key: "debtor_address_3_city",
      label: "Debtor Address 3 City",
      type: "string",
    },
    {
      key: "debtor_address_3_state",
      label: "Debtor Address 3 State",
      type: "select",
      selectOptions: usStates,
    },
    {
      key: "debtor_address_3_zip_code",
      label: "Debtor Address 3 Zip Code",
      type: "string",
    },
    {
      key: "debtor_address_3_type",
      label: "Debtor Address 3 Type",
      type: "select",
      selectOptions: addressType,
    },
    {
      key: "debtor_contact_1_first_name",
      label: "Debtor Contact 1 First Name",
      type: "string",
      validators: [
        {
          validate: "require_with",
          fields: [
            "debtor_contact_1_middle_name",
            "debtor_contact_1_email",
            "debtor_contact_1_do_not_contact",
            "debtor_contact_1_phone_1_number",
            "debtor_contact_1_phone_1_type",
            "debtor_contact_1_phone_2_number",
            "debtor_contact_1_phone_2_type",
          ],
        },
      ],
    },
    {
      key: "debtor_contact_1_middle_name",
      label: "Debtor Contact 1 Middle Name",
      type: "string",
    },
    {
      key: "debtor_contact_1_last_name",
      label: "Debtor Contact 1 Last Name",
      type: "string",
      validators: [
        {
          validate: "require_with",
          fields: [
            "debtor_contact_1_middle_name",
            "debtor_contact_1_email",
            "debtor_contact_1_do_not_contact",
            "debtor_contact_1_phone_1_number",
            "debtor_contact_1_phone_1_type",
            "debtor_contact_1_phone_2_number",
            "debtor_contact_1_phone_2_type",
          ],
        },
      ],
    },
    {
      key: "debtor_contact_1_email",
      label: "Debtor Contact 1 Email",
      type: "email",
    },
    {
      key: "debtor_contact_1_do_not_contact",
      label: "Debtor Contact 1 Do Not Contact",
      type: "checkbox",
    },
    {
      key: "debtor_contact_1_phone_1_number",
      label: "Debtor Contact 1 Phone 1 Number",
      type: "string",
      validators: [
        {
          validate: "require_with",
          fields: ["debtor_contact_1_phone_1_type"],
        },
      ],
    },
    {
      key: "debtor_contact_1_phone_1_type",
      label: "Debtor Contact 1 Phone 1 Type",
      type: "select",
      selectOptions: phoneType,
    },
    {
      key: "debtor_contact_1_phone_2_number",
      label: "Debtor Contact 1 Phone 2 Number",
      type: "string",
      validators: [
        {
          validate: "require_with",
          fields: ["debtor_contact_1_phone_2_type"],
        },
      ],
    },
    {
      key: "debtor_contact_1_phone_2_type",
      label: "Debtor Contact 1 Phone 2 Type",
      type: "select",
      selectOptions: phoneType,
    },
    {
      key: "debtor_contact_2_first_name",
      label: "Debtor Contact 2 First Name",
      type: "string",
      validators: [
        {
          validate: "require_with",
          fields: [
            "debtor_contact_2_middle_name",
            "debtor_contact_2_email",
            "debtor_contact_2_do_not_contact",
            "debtor_contact_2_phone_1_number",
            "debtor_contact_2_phone_1_type",
            "debtor_contact_2_phone_2_number",
            "debtor_contact_2_phone_2_type",
          ],
        },
      ],
    },
    {
      key: "debtor_contact_2_middle_name",
      label: "Debtor Contact 2 Middle Name",
      type: "string",
    },
    {
      key: "debtor_contact_2_last_name",
      label: "Debtor Contact 2 Last Name",
      type: "string",
      validators: [
        {
          validate: "require_with",
          fields: [
            "debtor_contact_2_middle_name",
            "debtor_contact_2_email",
            "debtor_contact_2_do_not_contact",
            "debtor_contact_2_phone_1_number",
            "debtor_contact_2_phone_1_type",
            "debtor_contact_2_phone_2_number",
            "debtor_contact_2_phone_2_type",
          ],
        },
      ],
    },
    {
      key: "debtor_contact_2_email",
      label: "Debtor Contact 2 Email",
      type: "email",
    },
    {
      key: "debtor_contact_2_do_not_contact",
      label: "Debtor Contact 2 Do Not Contact",
      type: "checkbox",
    },
    {
      key: "debtor_contact_2_phone_1_number",
      label: "Debtor Contact 2 Phone 1 Number",
      type: "string",
      validators: [
        {
          validate: "require_with",
          fields: ["debtor_contact_2_phone_1_type"],
        },
      ],
    },
    {
      key: "debtor_contact_2_phone_1_type",
      label: "Debtor Contact 2 Phone 1 Type",
      type: "select",
      selectOptions: phoneType,
    },
    {
      key: "debtor_contact_2_phone_2_number",
      label: "Debtor Contact 2 Phone 2 Number",
      type: "string",
      validators: [
        {
          validate: "require_with",
          fields: ["debtor_contact_2_phone_2_type"],
        },
      ],
    },
    {
      key: "debtor_contact_2_phone_2_type",
      label: "Debtor Contact 2 Phone 2 Type",
      type: "select",
      selectOptions: phoneType,
    },
    {
      key: "debtor_contact_3_first_name",
      label: "Debtor Contact 3 First Name",
      type: "string",
      validators: [
        {
          validate: "require_with",
          fields: [
            "debtor_contact_3_middle_name",
            "debtor_contact_3_email",
            "debtor_contact_3_do_not_contact",
            "debtor_contact_3_phone_1_number",
            "debtor_contact_3_phone_1_type",
            "debtor_contact_3_phone_2_number",
            "debtor_contact_3_phone_2_type",
          ],
        },
      ],
    },
    {
      key: "debtor_contact_3_middle_name",
      label: "Debtor Contact 3 Middle Name",
      type: "string",
    },
    {
      key: "debtor_contact_3_last_name",
      label: "Debtor Contact 3 Last Name",
      type: "string",
      validators: [
        {
          validate: "require_with",
          fields: [
            "debtor_contact_3_middle_name",
            "debtor_contact_3_email",
            "debtor_contact_3_do_not_contact",
            "debtor_contact_3_phone_1_number",
            "debtor_contact_3_phone_1_type",
            "debtor_contact_3_phone_2_number",
            "debtor_contact_3_phone_2_type",
          ],
        },
      ],
    },
    {
      key: "debtor_contact_3_email",
      label: "Debtor Contact 3 Email",
      type: "email",
    },
    {
      key: "debtor_contact_3_do_not_contact",
      label: "Debtor Contact 3 Do Not Contact",
      type: "checkbox",
    },
    {
      key: "debtor_contact_3_phone_1_number",
      label: "Debtor Contact 3 Phone 1 Number",
      type: "string",
      validators: [
        {
          validate: "require_with",
          fields: ["debtor_contact_3_phone_1_type"],
        },
      ],
    },
    {
      key: "debtor_contact_3_phone_1_type",
      label: "Debtor Contact 3 Phone 1 Type",
      type: "select",
      selectOptions: phoneType,
    },
    {
      key: "debtor_contact_3_phone_2_number",
      label: "Debtor Contact 3 Phone 2 Number",
      type: "string",
      validators: [
        {
          validate: "require_with",
          fields: ["debtor_contact_3_phone_2_type"],
        },
      ],
    },
    {
      key: "debtor_contact_3_phone_2_type",
      label: "Debtor Contact 3 Phone 2 Type",
      type: "select",
      selectOptions: phoneType,
    },
    {
      key: "debtor_email_1_address",
      label: "Debtor Email 1 Address",
      type: "email",
      validators: [
        {
          validate: "require_with",
          fields: ["debtor_email_1_status", "debtor_email_1_consent_date"],
        },
      ],
    },
    {
      key: "debtor_email_1_type",
      label: "Debtor Email 1 Type",
      type: "select",
      selectOptions: emailType,
    },
    {
      key: "debtor_email_1_status",
      label: "Debtor Email 1 Status",
      type: "select",
      selectOptions: emailStatusType,
    },
    {
      key: "debtor_email_1_consent_status",
      label: "Debtor Email 1 Consent Status",
      type: "select",
      selectOptions: emailConsentStatusType,
    },
    {
      key: "debtor_email_1_consent_date",
      label: "Debtor Email 1 Consent Date",
      type: DROMO_DATE_FORMAT,
    },
    {
      key: "debtor_email_2_address",
      label: "Debtor Email 2 Address",
      type: "email",
      validators: [
        {
          validate: "require_with",
          fields: ["debtor_email_2_status", "debtor_email_2_consent_date"],
        },
      ],
    },
    {
      key: "debtor_email_2_type",
      label: "Debtor Email 2 Type",
      type: "select",
      selectOptions: emailType,
    },
    {
      key: "debtor_email_2_status",
      label: "Debtor Email 2 Status",
      type: "select",
      selectOptions: emailStatusType,
    },
    {
      key: "debtor_email_2_consent_status",
      label: "Debtor Email 2 Consent Status",
      type: "select",
      selectOptions: emailConsentStatusType,
    },
    {
      key: "debtor_email_2_consent_date",
      label: "Debtor Email 2 Consent Date",
      type: DROMO_DATE_FORMAT,
    },
    {
      key: "debtor_email_3_address",
      label: "Debtor Email 3 Address",
      type: "email",
      validators: [
        {
          validate: "require_with",
          fields: ["debtor_email_3_status", "debtor_email_3_consent_date"],
        },
      ],
    },
    {
      key: "debtor_email_3_type",
      label: "Debtor Email 3 Type",
      type: "select",
      selectOptions: emailType,
    },
    {
      key: "debtor_email_3_status",
      label: "Debtor Email 3 Status",
      type: "select",
      selectOptions: emailStatusType,
    },
    {
      key: "debtor_email_3_consent_status",
      label: "Debtor Email 3 Consent Status",
      type: "select",
      selectOptions: emailConsentStatusType,
    },
    {
      key: "debtor_email_3_consent_date",
      label: "Debtor Email 3 Consent Date",
      type: DROMO_DATE_FORMAT,
    },
    {
      key: "debtor_phone_number_1",
      label: "Debtor Phone Number 1",
      type: "string",
    },
    {
      key: "debtor_phone_type_1",
      label: "Debtor Phone Type 1",
      type: "select",
      selectOptions: phoneType,
    },
    {
      key: "debtor_phone_is_primary_1",
      label: "Debtor Phone Is Primary 1",
      type: "checkbox",
    },
    {
      key: "debtor_phone_number_2",
      label: "Debtor Phone Number 2",
      type: "string",
    },
    {
      key: "debtor_phone_type_2",
      label: "Debtor Phone Type 2",
      type: "select",
      selectOptions: phoneType,
    },
    {
      key: "debtor_phone_is_primary_2",
      label: "Debtor Phone Is Primary 2",
      type: "checkbox",
    },
    {
      key: "debtor_phone_number_3",
      label: "Debtor Phone Number 3",
      type: "string",
    },
    {
      key: "debtor_phone_type_3",
      label: "Debtor Phone Type 3",
      type: "select",
      selectOptions: phoneType,
    },
    {
      key: "debtor_phone_is_primary_3",
      label: "Debtor Phone Is Primary 3",
      type: "checkbox",
    },
    {
      key: "debtor_employer_name",
      label: "Debtor Employer Name",
      type: "string",
    },
    {
      key: "debtor_employer_address_1",
      label: "Debtor Employer Address Line 1",
      type: "string",
    },
    {
      key: "debtor_employer_address_2",
      label: "Debtor Employer Address Line 2",
      type: "string",
    },
    {
      key: "debtor_employer_address_city",
      label: "Debtor Employer Address City",
      type: "string",
    },
    {
      key: "debtor_employer_address_state",
      label: "Debtor Employer Address State",
      type: "select",
      selectOptions: usStates,
    },
    {
      key: "debtor_employer_address_zip_code",
      label: "Debtor Employer Address Zip Code",
      type: "string",
    },
    {
      key: "debtor_employer_phone_number",
      label: "Debtor Employer Phone Number",
      type: "string",
    },
    {
      key: "debtor_notes",
      label: "Debtor Notes",
      type: "string",
    },
    {
      key: "special_comment_code",
      label: "Special Comment Code",
      type: "select",
      selectOptions: [
        { label: "B - Payments Managed by Credit Counseling Service", value: "B" },
        { label: "C - Paid by Co-Maker", value: "C" },
        { label: "M - Account Closed at Credit Grantor's Request", value: "M" },
        { label: "O - Account Transferred to Another Lender", value: "O" },
        { label: "S - Special Handling", value: "S" },
        { label: "V - Adjustment Pending", value: "V" },
        { label: "AB - Debt Being Paid Through Insurance", value: "AB" },
        { label: "AC - Paying Under a Partial Payment Agreement", value: "AC" },
        { label: "AH - Purchased by Another Lender", value: "AH" },
        { label: "AI - Recalled to Active Military Duty", value: "AI" },
        { label: "AJ - Payroll Deduction", value: "AJ" },
        { label: "AM - Account Payment Assured by Wage Garnishment", value: "AM" },
        { label: "AN - Account Acquired by RTC/FDIC", value: "AN" },
        { label: "AP - Credit Line Suspended", value: "AP" },
        { label: "AR - Contingent Liability", value: "AR" },
        { label: "AS - Account Closed Due to Refinance", value: "AS" },
        { label: "AT - Account Closed Due to Transfer", value: "AT" },
        { label: "AU - Account Paid in Full for Less than Full Balance", value: "AU" },
        { label: "AV - First Payment Never Received", value: "AV" },
        { label: "AW - Affected by Natural Disaster", value: "AW" },
        { label: "BA - Transferred to Recovery", value: "BA" },
        { label: "BL - Credit Card Lost or Stolen", value: "BL" },
        { label: "BP - Paid through Insurance", value: "BP" },
        { label: "BZ - Judgment Granted", value: "BZ" },
        { label: "CS - For Use by Child Support Agencies Only", value: "CS" },
      ],
    },
    {
      key: "compliance_condition_code",
      label: "Compliance Condition Code",
      type: "select",
      selectOptions: [
        { label: "XA - Account closed at consumer's request", value: "XA" },
        { label: "XB - Account information disputed by consumer", value: "XB" },
        { label: "XC - Completed investigation of FCRA", value: "XC" },
        { label: "XD - Closed at consumer's request & in dispute under FCRA", value: "XD" },
        {
          label: "XE - Closed at cons request, dispute completed, consumer disagrees",
          value: "XE",
        },
        { label: "XF - Account in dispute under Fair Credit Billing Act", value: "XF" },
        { label: "XG - FCBA Dispute resolved (consumer disagrees)", value: "XG" },
        {
          label: "XH - Previously in dispute (now resolved, reported by credit grantor)",
          value: "XH",
        },
        { label: "XR - Removes the most recently reported Compliance Condition Code", value: "XR" },
      ],
    },
    {
      key: "bcii_code",
      label: "BCII Code",
      type: "select",
      selectOptions: [
        { label: "A - Petition for Chapter 7 Bankruptcy", value: "A" },
        { label: "B - Petition for Chapter 11 Bankruptcy", value: "B" },
        { label: "C - Petition for Chapter 12 Bankruptcy", value: "C" },
        { label: "D - Petition for Chapter 13 Bankruptcy", value: "D" },
        { label: "E - Discharged through Bankruptcy Chapter 7", value: "E" },
        { label: "F - Discharged through Bankruptcy Chapter 11", value: "F" },
        { label: "G - Discharged through Bankruptcy Chapter 12", value: "G" },
        { label: "H - Discharged through Bankruptcy Chapter 13", value: "H" },
        { label: "I - Chapter 7 Bankruptcy Dismissed", value: "I" },
        { label: "J - Chapter 11 Bankruptcy Dismissed", value: "J" },
        { label: "K - Chapter 12 Bankruptcy Dismissed", value: "K" },
        { label: "L - Chapter 13 Bankruptcy Dismissed", value: "L" },
        { label: "M - Chapter 7 Bankruptcy Withdrawn", value: "M" },
        { label: "N - Chapter 11 Bankruptcy Withdrawn", value: "N" },
        { label: "O - Chapter 12 Bankruptcy Withdrawn", value: "O" },
        { label: "P - Chapter 13 Bankruptcy Withdrawn", value: "P" },
        { label: "Q - Removes previously reported Bankruptcy Indicator", value: "Q" },
        { label: "R - Reaffirmation of Debt", value: "R" },
        { label: "S - Removes previously reported Reaffirmation of Debt", value: "S" },
        { label: "T - Credit Grantor Cannot Locate Consumer", value: "T" },
        { label: "U - Consumer Now Located", value: "U" },
        { label: "V - Chapter 7 Reaffirmation of Debt Rescinded", value: "V" },
        { label: "Z - Bankruptcy (Undesignated Chapter)", value: "Z" },
        { label: "1A - Personal Receivership", value: "1A" },
        { label: "2A - Lease Assumption", value: "2A" },
      ],
    },
    {
      key: "ecoa_code",
      label: "ECOA Code",
      type: "select",
      selectOptions: [
        {
          label:
            "Authorized User - A joint account where the borrower is an authorized user, but has no contractual responsibility",
          value: "authorized_user",
        },
        {
          label: "Individual Account - An account solely for this borrower",
          value: "individual_account",
        },
        {
          label: "Borrower's Account - An account solely for the borrower",
          value: "borrowers_account",
        },
        {
          label: "Co-borrower's Account - An account solely for the co-borrower",
          value: "coborrowers_account",
        },
        {
          label: "Joint Account - An account for which both spouses are contractually liable",
          value: "joint_account",
        },
        {
          label:
            "Maker - An account where borrower is primarily responsible, having a cosigner or co-maker",
          value: "maker",
        },
        {
          label:
            "Co-maker - An account for which the borrower is the co-signer, with no spousal relationship, who becomes liable if the primary signer or Maker defaults",
          value: "comaker",
        },
        {
          label:
            "Participating Account - A joint account for which contractual liability cannot be determined",
          value: "participating_account",
        },
        {
          label:
            "Terminated - A joint or co-signed account where the borrower is no longer associated with the account",
          value: "terminated",
        },
        { label: "Deceased - The borrower has been reported deceased", value: "deceased" },
        {
          label: "Undesignated Account - An account that has not yet been designated",
          value: "undesignated_account",
        },
      ],
    },
    {
      key: "credit_reporting_status",
      label: "Credit Reporting Status",
      type: "select",
      selectOptions: [
        { label: "U - Being Reported", value: "U" },
        { label: "R - Being Reported", value: "R" },
        { label: "S - Closed", value: "S" },
        { label: "N - Never Been Reported", value: "N" },
        { label: "H - Suppressed", value: "H" },
        { label: "C - Will Be Closed", value: "C" },
      ],
    },
    {
      key: "credit_bureau_number",
      label: "Credit Bureau Number",
      type: "string",
    },
    {
      key: "is_skip_credit_reporting",
      label: "Skip Credit Reporting",
      type: "checkbox",
    },
    {
      key: "credit_bureau_notes",
      label: "Credit Bureau Notes",
      type: "string",
    },
    {
      key: "current_creditor_name",
      label: "Current Creditor Name",
      type: "string",
    },
    {
      key: "original_creditor_name",
      label: "Original Creditor Name",
      type: "string",
    },
    {
      key: "legal_fees",
      label: "Original Legal Fees",
      type: ["number", "usd"],
    },
    {
      key: "check_fees",
      label: "Original Check Fees",
      type: ["number", "usd"],
    },
    {
      key: "collection_fees",
      label: "Original Collection Fees",
      type: ["number", "usd"],
    },
    {
      key: "misc_fees",
      label: "Original Misc Fees",
      type: ["number", "usd"],
    },
    {
      key: "attorney_fees",
      label: "Original Attorney Fees",
      type: ["number", "usd"],
    },
    {
      key: "treble_damages",
      label: "Original Treble Damages",
      type: ["number", "usd"],
    },
    {
      key: "handling_fees",
      label: "Original Handling Fees",
      type: ["number", "usd"],
    },
    {
      key: "court_fees",
      label: "Original Court Fees",
      type: ["number", "usd"],
    },
    {
      key: "service_fees",
      label: "Original Service Fees",
      type: ["number", "usd"],
    },
    {
      key: "lien_fees",
      label: "Original Lien Fees",
      type: ["number", "usd"],
    },
  ];
  // apply required together validation to fields
  fields.forEach((field) => {
    accountRequiredTogetherFields.forEach((fieldSet) => {
      if (fieldSet.has(field.key)) {
        (field.validators ??= []).push({
          validate: "require_with",
          fields: Array.from(fieldSet).filter((key) => key !== field.key),
        });
      }
    });
  });
  return fields;
}
