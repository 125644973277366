import { Form, Modal, Select } from "antd";
import { useFetchFeePlansQuery } from "features/feePlans/feePlansAPI";

export default function PaymentAllocationFeePlanModal({ open, onOk, onCancel }) {
  const [form] = Form.useForm();
  const { data: feePlans = [] } = useFetchFeePlansQuery();

  const onSubmit = async () => {
    const data = await form.validateFields();
    onOk(data);
  };

  return (
    <Modal
      maskClosable={false}
      title="Update Fee Plan"
      open={open}
      onOk={onSubmit}
      onCancel={onCancel}
    >
      <Form
        form={form}
        layout="vertical"
        validateMessages={{ required: "This is a required field" }}
        initialValues={{ feePlanId: null }}
      >
        <Form.Item name="feePlanId">
          <Select
            popupMatchSelectWidth={false}
            placeholder="Select Fee Plan"
            allowClear
            options={feePlans.map((d) => ({
              label: d.name,
              value: d.id,
            }))}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
