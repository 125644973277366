import {
  EllipsisOutlined,
  ExclamationCircleOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Modal, Popover, Row, Space, Table, Tag, message } from "antd";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import { DATE_FORMAT, DATE_TIME_FORMAT, PAYMENT_INTENT_STATUS } from "common/constants";
import { toTitleCase } from "common/utils";
import {
  useBulkDebtorDeletePaymentIntentsMutation,
  useGetPaymentIntentsQuery,
  useRefundPaymentIntentMutation,
  useUnsettlePaymentIntentMutation,
} from "features/payments/paymentsAPI";
import { useFetchDebtorProfileQuery } from "features/accountsTable/agencyPortal/accountsTableAPI";
import { PERMISSIONS } from "features/permissions";
import useAuthorizations from "features/permissions/hooks/useAuthorizations";
import { TransactionsTable } from "features/scheduledPayments/transactionsTable";
import moment from "moment-timezone";

const StyledEllipsisOutlined = styled(EllipsisOutlined)`
  font-size: 24px;
`;

const StyledErrorText = styled.div`
  color: red;
`;

const StyledQuestionCircleOutlined = styled(QuestionCircleOutlined)`
  color: #1677ff;
  margin-left: 4px;
`;

const { confirm } = Modal;

function ExpandedRow({ id }) {
  return <TransactionsTable id={id} />;
}

export default function PaymentHistory() {
  const { debtorId } = useParams();
  const navigate = useNavigate();
  const [expandedKeys, setExpandedKeys] = useState([]);
  const { data: paymentIntents, isLoading } = useGetPaymentIntentsQuery({ debtorId });
  const [deletePaymentIntent] = useBulkDebtorDeletePaymentIntentsMutation();
  const [refundPaymentIntent] = useRefundPaymentIntentMutation();
  const [unsettlePaymentIntent] = useUnsettlePaymentIntentMutation();
  const { data: debtorPersonalInfo } = useFetchDebtorProfileQuery({ debtorId });

  const {
    data: {
      [PERMISSIONS.PAYMENT__DELETE]: isPaymentDeleteAuthorized,
      [PERMISSIONS.PAYMENT__REVERSE]: isPaymentReversalAuthorized,
    },
  } = useAuthorizations();

  const items = [
    {
      key: "reverse",
      label: "Reverse Payment",
      actionHandler: refundPaymentIntent,
      isVisible: (record) =>
        isPaymentReversalAuthorized && record.status === PAYMENT_INTENT_STATUS.EXECUTED,
    },
    {
      key: "markAsReversed",
      label: "Mark as Reversed (Aktos Only)",
      actionHandler: (payload) => {
        return refundPaymentIntent({ ...payload, aktosOnly: true });
      },
      isVisible: (record) =>
        isPaymentReversalAuthorized &&
        record.status === PAYMENT_INTENT_STATUS.EXECUTED &&
        !record.isLoggingOnly,
    },
    {
      key: "delete",
      label: "Delete Payment",
      actionHandler: deletePaymentIntent,
      isVisible: (record) =>
        // Always available for logging only payments.
        // For processed payments, refund/void attempt is required before the delete option appears.
        isPaymentDeleteAuthorized &&
        (record.isLoggingOnly ||
          [
            PAYMENT_INTENT_STATUS.REFUNDED,
            PAYMENT_INTENT_STATUS.VOIDED,
            PAYMENT_INTENT_STATUS.EXECUTE_ERROR,
            PAYMENT_INTENT_STATUS.REFUND_ERROR,
            PAYMENT_INTENT_STATUS.VOID_ERROR,
          ].includes(record.status)),
    },
    {
      key: "unsettle",
      label: "Undo Settlement",
      actionHandler: unsettlePaymentIntent,
      isVisible: (record) =>
        record.status === PAYMENT_INTENT_STATUS.EXECUTED && record.isSettlement,
    },
  ];

  const handleOk = (item, key, record) => {
    confirm({
      title:
        key === "markAsReversed"
          ? "Are you sure you want to mark this payment as reversed (Aktos only)? Please ensure the reversal has already been processed by the external payment processor."
          : `Are you sure you want to ${key} this payment?`,
      icon: <ExclamationCircleOutlined />,
      async onOk() {
        if (key === "delete" && record.debtorId.toString() !== debtorId.toString()) {
          message.error(
            `Payment cannot be deleted because ${debtorPersonalInfo?.name} is not the main debtor of this payment`,
          );
          return;
        }

        const result = await item.actionHandler({
          debtorId,
          paymentIntentId: record.id,
          paymentIntentIds: [record.id],
        });

        if ("error" in result) {
          switch (key) {
            case "markAsReversed":
              message.error("Failed to mark payment as reversed");
              break;
            default:
              message.error(`Failed to ${key} payment`);
          }
        }
        if ("data" in result) {
          switch (key) {
            case "delete":
              message.success("Payment successfully deleted");
              break;
            case "reverse":
              message.success("Payment successfully reversed");
              break;
            case "markAsReversed":
              message.success("Payment successfully marked as reversed");
              break;
            case "unsettle":
              message.success("Payment successfully unsettled");
              break;
            default:
              message.success(`Payment successfully ${key}ed`);
          }
          setExpandedKeys([record.id]);
        }
      },
    });
  };

  const handleMenuClick = ({ item, key, keyPath, domEvent }, record) => {
    // This prevents navigation to invoice detail when clicking on a dropdown item
    domEvent.preventDefault();
    domEvent.stopPropagation();

    // Call the action handler defined in the items array
    handleOk(item.props, key, record);
  };

  const columns = [
    {
      title: "Payment ID",
      dataIndex: "id",
      key: "id",
      // NOTE: On the PaymentHistoryTab, we sort by ID, since these payments have already been processed.
      sorter: (a, b) => parseInt(a.id, 10) - parseInt(b.id, 10),
      render: (text, record) =>
        record.isSettlement ? (
          <>
            <Tag color="green">SETTLEMENT</Tag>
            {text}
          </>
        ) : (
          text
        ),
    },
    {
      title: "Paid By",
      dataIndex: "debtorId",
      key: "debtorId",
      render: (text, record) => {
        return (
          <div key={record.debtorId}>
            <Button
              type="link"
              onClick={() => {
                navigate(`/debtors/${record.debtorId}`);
              }}
            >
              {record.debtorId}
            </Button>
          </div>
        );
      },
    },
    {
      title: "Account IDs",
      dataIndex: "accountIds",
      key: "accountIds",
      render: (text, record) => record.accountIds.join(", "),
    },
    {
      title: "Scheduled Date",
      dataIndex: "scheduledDate",
      key: "scheduledDate",
      render: (text, record) =>
        record.scheduledDate && moment(record.scheduledDate).format(DATE_FORMAT),
      sorter: (a, b) => moment(a.scheduledDate).unix() - moment(b.scheduledDate).unix(),
    },
    {
      title: "Last Transaction",
      dataIndex: "lastTransaction",
      key: "lastTransaction",
      render: (_, record) =>
        record.lastTransaction
          ? moment(record.lastTransaction.createdAt).format(DATE_TIME_FORMAT)
          : "-",
    },
    {
      title: "Last Successful Transaction",
      dataIndex: "lastSuccessfulExecute",
      key: "lastSuccessfulExecute",
      render: (_, record) =>
        record.lastSuccessfulExecute
          ? moment(record.lastSuccessfulExecute.createdAt).format(DATE_TIME_FORMAT)
          : "-",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text, record) => `$${record.totalAmount}`,
    },
    {
      title: "Reference #",
      dataIndex: "referenceNumber",
      key: "referenceNumber",
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (text, record) => {
        if (
          text === PAYMENT_INTENT_STATUS.VOID_ERROR ||
          text === PAYMENT_INTENT_STATUS.REFUND_ERROR ||
          text === PAYMENT_INTENT_STATUS.EXECUTE_ERROR
        ) {
          return (
            <Row align="middle">
              <StyledErrorText>error</StyledErrorText>
              <Popover
                content="Failed to communicate with payment processor. Please log into your payment portal, cancel any existing transaction, and delete this payment to start over."
                trigger="hover"
                placement="right"
              >
                <StyledQuestionCircleOutlined />
              </Popover>
            </Row>
          );
        }
        if (record.isLastTransactionDeclined) {
          return <StyledErrorText>declined</StyledErrorText>;
        }
        return text;
      },
    },
    {
      title: "Payment Method",
      key: "paymentMethod",
      render: (text, record) => {
        const { type, cardLast4Digits, bankAccountLast4Digits } = record.paymentMethod ?? {};
        if (type === "process_card") {
          return `Card ending in ${cardLast4Digits}`;
        }
        if (type === "process_ach") {
          return `ACH ending in ${bankAccountLast4Digits}`;
        }
        if (type === "paid_to_creditor_legal") {
          return `Paid to Creditor (Legal Payment)`;
        }
        if (type === "forwarding_entity_legal") {
          return `Paid to Forwarding Entity (Legal Payment)`;
        }
        return toTitleCase({ str: type, delimiter: "_" });
      },
    },
    {
      title: "Actions",
      fixed: "right",
      dataIndex: "action",
      align: "center",
      key: "action",
      render: (_, record) => {
        const itemsToDisplay = items.filter((each) => each.isVisible(record));
        if (itemsToDisplay.length === 0) {
          return <div>No actions available</div>;
        }
        return (
          <Space size="middle">
            <Dropdown
              menu={{
                items: itemsToDisplay,
                onClick: (clickHandlerProp) => handleMenuClick(clickHandlerProp, record),
              }}
              trigger={["click"]}
            >
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                type="text"
              >
                <StyledEllipsisOutlined />
              </Button>
            </Dropdown>
          </Space>
        );
      },
    },
  ];

  return (
    <Table
      scroll={{ x: "max-content" }}
      loading={isLoading}
      bordered
      rowClassName={() => "editable-row"}
      pagination={{
        defaultPageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: [10, 20, 30, 100],
      }}
      // @ts-ignore
      columns={columns}
      dataSource={paymentIntents?.filter(
        (paymentIntent) =>
          paymentIntent.isLastTransactionDeclined ||
          [
            PAYMENT_INTENT_STATUS.EXECUTED,
            PAYMENT_INTENT_STATUS.REFUNDED,
            PAYMENT_INTENT_STATUS.VOIDED,
            PAYMENT_INTENT_STATUS.EXECUTE_ERROR,
            PAYMENT_INTENT_STATUS.REFUND_ERROR,
            PAYMENT_INTENT_STATUS.VOID_ERROR,
          ].includes(paymentIntent.status),
      )}
      expandable={{
        expandedRowRender: ExpandedRow,
        expandedRowKeys: expandedKeys,
        onExpandedRowsChange: (keys) => {
          setExpandedKeys((prevKeys) => {
            // This logic takes care of closing old expanded rows
            return keys.filter((x) => !prevKeys.includes(x));
          });
        },
      }}
    />
  );
}
