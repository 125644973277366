import { Button, Col, Form, Row, Select } from "antd";
import SkipTracingOptionsModal from "features/bulkActions/components/skipTracingOptionsModal";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import { useState } from "react";
import styled from "styled-components";

const StyledSelect = styled(Select)`
  max-width: 400px !important;
  margin-right: 4px;
`;

export default function SkipTraceSubForm({ setHideParentModal }) {
  const [skipTracingOptionsModalOpen, setSkipTracingOptionsModalOpen] = useState(false);
  const form = Form.useFormInstance();
  const { data: constants } = useFetchBackendConstantsQuery();

  const onConfigureSkipTracingClick = () => {
    const provider = form.getFieldValue(["operations", "skipTrace", "provider"]);
    if (provider) {
      setSkipTracingOptionsModalOpen(true);
      setHideParentModal(true);
    }
  };

  const onProviderChange = (value) => {
    if (value) {
      form.setFieldValue(["operations", "skipTrace", "providerConfig"], undefined);
    }
  };

  return (
    <>
      <Row align="middle">
        <Col span={12}>
          <Form.Item
            name={["operations", "skipTrace", "provider"]}
            label="Provider"
            rules={[
              {
                required: true,
                message: "Please select a provider",
              },
            ]}
          >
            <StyledSelect
              showSearch
              popupMatchSelectWidth={false}
              options={constants?.skipTraceProviders.map((provider) => ({
                label: provider.display,
                value: provider.value,
              }))}
              placeholder="Select provider..."
              allowClear
              onChange={onProviderChange}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item hidden name={["operations", "skipTrace", "providerConfig"]} />
          <Form.Item
            noStyle
            shouldUpdate={(prev, curr) =>
              prev?.operations?.skipTrace?.provider !== curr?.operations?.skipTrace?.provider
            }
          >
            {({ getFieldValue }) => {
              const provider = getFieldValue(["operations", "skipTrace", "provider"]);
              const options = getFieldValue(["operations", "skipTrace", "providerConfig"]);
              if (!provider) {
                return null;
              }
              if (!options) {
                return (
                  <Button type="link" onClick={onConfigureSkipTracingClick}>
                    Configure
                  </Button>
                );
              }
              return (
                <Button type="link" onClick={onConfigureSkipTracingClick}>
                  Re-configure
                </Button>
              );
            }}
          </Form.Item>
        </Col>
      </Row>
      <SkipTracingOptionsModal
        open={skipTracingOptionsModalOpen}
        onCancel={() => {
          setSkipTracingOptionsModalOpen(false);
          setHideParentModal(false);
        }}
        onSubmit={(data) => {
          form.setFieldValue(["operations", "skipTrace", "providerConfig"], data);
          setSkipTracingOptionsModalOpen(false);
          setHideParentModal(false);
        }}
        title="Configure Skip Tracing"
        provider={form.getFieldValue(["operations", "skipTrace", "provider"])}
      />
    </>
  );
}
