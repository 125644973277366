import { Checkbox, Form, Input, Modal, Select, message } from "antd";
import { DATE_TIME_FORMAT } from "common/constants";
import { formatError } from "common/redux/middleware/queryErrorLogger";
import { capitalizeFirstLetter } from "common/utils";
import { AktDatePicker } from "components/aktDatePicker";
import { useUserType } from "features/auth";
import { usePostDebtorCommunicationHistoryMutation } from "features/communicationsTable/agencyPortal/communicationsTableAPI";
import { usePostCreditorPortalDebtorCommunicationHistoryMutation } from "features/communicationsTable/creditorPortal/communicationsTableAPI";
import { useFetchDebtorContactsQuery } from "features/debtorSider/agencyPortal/debtorSiderAPI";
import { useFetchCreditorPortalDebtorContactsQuery } from "features/debtorSider/creditorPortal/debtorSiderAPI";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import moment from "moment-timezone";
import { useState } from "react";
import styled from "styled-components";

const StyledForm = styled(Form)`
  margin-top: 12px;
  max-width: 400px;
`;

const DEBTOR_CONTACT_VALUE = "debtor_contact";

function CommunicationModal({ title, open, onOk, onCancel, debtorId }) {
  const { isAgencyUserType } = useUserType();
  const [componentSize, setComponentSize] = useState("default");
  const { data: agencyDebtorContacts } = useFetchDebtorContactsQuery(
    { debtorId },
    { skip: !isAgencyUserType },
  );
  const { data: creditorPortalDebtorContacts } = useFetchCreditorPortalDebtorContactsQuery(
    { debtorId },
    { skip: isAgencyUserType },
  );
  const debtorContacts = isAgencyUserType ? agencyDebtorContacts : creditorPortalDebtorContacts;

  const [addAgencyCommunication, { isLoading: isAgencyCommunicationLoading }] =
    usePostDebtorCommunicationHistoryMutation();
  const [addCreditorCommunication, { isLoading: isCreditorCommunicationLoading }] =
    usePostCreditorPortalDebtorCommunicationHistoryMutation();
  const [addCommunicationAction, isLoading] = isAgencyUserType
    ? [addAgencyCommunication, isAgencyCommunicationLoading]
    : [addCreditorCommunication, isCreditorCommunicationLoading];

  const { data: constants } = useFetchBackendConstantsQuery();

  const extraRecipientOptions = (constants?.debtorCommunicationContactTypes ?? [])
    .filter(({ value }) => value !== DEBTOR_CONTACT_VALUE)
    .map(({ value, display }) => ({
      value,
      label: display,
    }));

  const onFormLayoutChange = ({ size }) => {
    setComponentSize(size);
  };
  const [form] = Form.useForm();

  const onOkPressed = async () => {
    let values;
    try {
      // Get the values from the form, then pass them to the outer `onOk` callback
      values = await form.validateFields();
    } catch {
      return null;
    }
    const result = await addCommunicationAction({ communication: values, debtorId });
    if ("data" in result) {
      onOk();
      message.success("Communication log added successfully!");
      form.resetFields();
    }
    if ("error" in result) {
      form.setFields(formatError(result.error));
    }
  };

  return (
    <Modal
      confirmLoading={isLoading}
      maskClosable={false}
      title={title}
      open={open}
      onOk={onOkPressed}
      onCancel={() => {
        onCancel();
        form.resetFields();
      }}
      okButtonProps={{
        loading: isLoading,
      }}
    >
      <StyledForm
        form={form}
        layout="vertical"
        initialValues={{
          size: componentSize,
          communicationStartTime: moment().format(DATE_TIME_FORMAT),
          communicationOrigin: "outbound",
        }}
        onValuesChange={onFormLayoutChange}
        // @ts-ignore
        size={componentSize}
        labelAlign="left"
      >
        <Form.Item
          label="Contact method"
          name="contactMethod"
          rules={[{ required: true, message: "Contact method is required" }]}
          initialValue="phone"
        >
          <Select
            options={constants?.contactMethods.map((method) => ({
              label: method.display,
              value: method.value,
            }))}
          />
        </Form.Item>
        <Form.Item label="Communication Origin" name="communicationOrigin">
          <Select
            options={constants?.communicationOrigin
              ?.filter((communicationOrigin) => Boolean(communicationOrigin.value))
              .map((communicationOrigin) => ({
                label: communicationOrigin.display,
                value: communicationOrigin.value,
              }))}
          />
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.contactMethod !== currentValues.contactMethod
          }
        >
          {({ getFieldValue }) => {
            const contactMethod = getFieldValue("contactMethod");
            if (contactMethod?.toLowerCase() === "phone") {
              return (
                <Form.Item label="Call Results" name="callResults">
                  <Select
                    mode="multiple"
                    allowClear
                    options={constants?.callResults.map((resultType) => ({
                      label: resultType.display,
                      value: resultType.value,
                    }))}
                  />
                </Form.Item>
              );
            }
            if (contactMethod?.toLowerCase() === "text") {
              return (
                <Form.Item label="Text Results" name="textResults">
                  <Select
                    mode="multiple"
                    allowClear
                    options={constants?.textResults.map((resultType) => ({
                      label: resultType.display,
                      value: resultType.value,
                    }))}
                  />
                </Form.Item>
              );
            }
            if (contactMethod?.toLowerCase() === "mail") {
              return (
                <Form.Item label="Mail Results" name="mailResults">
                  <Select
                    mode="multiple"
                    allowClear
                    options={constants?.mailResults.map((resultType) => ({
                      label: resultType.display,
                      value: resultType.value,
                    }))}
                  />
                </Form.Item>
              );
            }
          }}
        </Form.Item>
        <Form.Item label="Communication Results" name="communicationResults">
          <Select
            mode="multiple"
            allowClear
            options={constants?.communicationResults.map((resultType) => ({
              label: resultType.display,
              value: resultType.value,
            }))}
          />
        </Form.Item>
        <Form.Item
          label="Recipient"
          name="recipient"
          rules={[{ required: true, message: "A recipient is required" }]}
        >
          <Select
            options={[
              ...(debtorContacts
                ?.filter((contact) => !contact.isArchived)
                .map((debtorContact) => ({
                  value: debtorContact.id,
                  label: [debtorContact.firstName, debtorContact.middleName, debtorContact.lastName]
                    .filter((name) => !!name)
                    .join(" "),
                })) ?? []),
              ...extraRecipientOptions,
            ]}
          />
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.recipient !== currentValues.recipient
          }
        >
          {({ getFieldValue, setFieldValue }) => {
            const recipient = getFieldValue("recipient");
            if (recipient === undefined) return null;

            const extraOptionsValues = extraRecipientOptions.map((option) => option.value);
            const isExtraOption = extraOptionsValues.includes(recipient);

            setFieldValue("debtorContactId", isExtraOption ? null : recipient);
            setFieldValue("contactType", isExtraOption ? recipient : DEBTOR_CONTACT_VALUE);

            // recipient is either an ID or a value of extra option
            // we split recipient into 2 hidden inputs
            return (
              <>
                <Form.Item name="debtorContactId" hidden>
                  <Input hidden />
                </Form.Item>
                <Form.Item name="contactType" hidden>
                  <Input hidden />
                </Form.Item>
              </>
            );
          }}
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.debtorContactId !== currentValues.debtorContactId ||
            prevValues.contactMethod !== currentValues.contactMethod
          }
        >
          {({ getFieldValue, setFieldValue }) => {
            const contactMethod = getFieldValue("contactMethod");

            if (["phone", "text"].includes(contactMethod?.toLowerCase())) {
              const debtorContactId = getFieldValue("debtorContactId");
              const debtorContact = debtorContacts?.find(
                (contact) => contact.id === debtorContactId,
              );
              const options =
                debtorContact?.phones?.map((phone) => ({
                  label: `${phone.number} - ${capitalizeFirstLetter(phone.type)}`,
                  value: phone.number,
                })) ?? [];

              // Preselect if only 1 option is available
              if (options.length === 1) {
                setFieldValue("phoneNumber", options[0].value);
              } else {
                setFieldValue("phoneNumber", undefined);
              }

              return (
                <Form.Item label="Phone Number" name="phoneNumber">
                  <Select options={options} />
                </Form.Item>
              );
            }
          }}
        </Form.Item>
        <Form.Item
          label="Communication Start"
          name="communicationStartTime"
          rules={[{ required: true }]}
        >
          <AktDatePicker
            showSecond={false}
            minuteStep={5}
            showTime
            placeholder="Select Date/Time"
            placement="bottomLeft"
          />
        </Form.Item>
        <Form.Item label="Preferred Follow-up" name="preferredFollowUpTime">
          <AktDatePicker
            showSecond={false}
            minuteStep={5}
            showTime
            placement="bottomLeft"
            placeholder="Select Date/Time"
          />
        </Form.Item>
        <Form.Item label="Notes" name="notes">
          <Input.TextArea />
        </Form.Item>
        <Form.Item label="Consent for Text" name="isConsentForText">
          <Select
            allowClear
            options={[
              {
                label: "Yes",
                value: "yes",
              },
              {
                label: "No",
                value: "no",
              },
            ]}
          />
        </Form.Item>
        <Form.Item label="Consent for Email" name="isConsentForEmail">
          <Select
            allowClear
            options={[
              {
                label: "Yes",
                value: "yes",
              },
              {
                label: "No",
                value: "no",
              },
            ]}
          />
        </Form.Item>
        <Form.Item label="Preferred Language" name="preferredLanguage">
          <Select
            allowClear
            options={constants?.languages.map((language) => ({
              label: language.display,
              value: language.value,
            }))}
          />
        </Form.Item>
        <Form.Item label="Preferred Communication Medium" name="preferredCommunication">
          <Select
            allowClear
            options={constants?.contactMethods.map((method) => ({
              label: method.display,
              value: method.value,
            }))}
          />
        </Form.Item>
        <Form.Item name="isCeaseCommunication" valuePropName="checked">
          <Checkbox>Cease Communication</Checkbox>
        </Form.Item>
      </StyledForm>
    </Modal>
  );
}

export default CommunicationModal;
