import { SettingOutlined, TeamOutlined } from "@ant-design/icons";
import { Menu } from "antd";
import { PERMISSIONS } from "features/permissions";
import useAuthorizations from "features/permissions/hooks/useAuthorizations";
import useFeatureFlags from "features/permissions/hooks/useFeatureFlags";
import { Link } from "react-router-dom";

const getItem = (label, key, icon, children, type) => {
  return {
    key,
    // icon,
    children,
    label,
    type,
  };
};

function AppMenu() {
  const {
    data: {
      [PERMISSIONS.DOCUMENT_CENTER__GET]: isDocumentCenterAuthorized,
      [PERMISSIONS.ACCOUNT_ASSIGNMENT_RULE__GET]: isAccountAssignmentConfigurationAuthorized,
      [PERMISSIONS.ACCOUNT_PRIORITY_RULE__GET]: isPriorityQueueConfigurationAuthorized,
      [PERMISSIONS.CREDITOR__GET]: isCreditorsAuthorized,
      [PERMISSIONS.CREDITOR_TAG__GET]: isCreditorTagAuthorized,
      [PERMISSIONS.FORWARDING_ENTITY__GET]: isForwardingAgenciesAuthorized,
      [PERMISSIONS.ACCOUNT_COMMUNICATION_SEQUENCE__GET]: isCommunicationSequenceEnabled,
      [PERMISSIONS.COURT_OF_LAW__GET]: isSettingsCourtsOfLawAuthorized,
      [PERMISSIONS.AGENCY_CBR_CONFIG__GET]: isSettingsCreditReportingAuthorized,
      [PERMISSIONS.LETTER_TEMPLATE__GET]: isSettingsLetterTemplatesAuthorized,
      [PERMISSIONS.INVOICE__GET]: isInvoicesAuthorized,
      [PERMISSIONS.DATA_IMPORT__GET]: isDataImportAuthorized,
      [PERMISSIONS.LAW_FIRM__GET]: isSettingsLawFirmsAuthorized,
      [PERMISSIONS.ACCOUNT__GET]: isAccountWorklistAuthorized,
      [PERMISSIONS.USER__MANAGE]: isUserManagementAuthorized,
      [PERMISSIONS.FEE_PLAN_RULE__GET]: isFeePlanRuleAuthorized,
      [PERMISSIONS.SETTLEMENT_PAYMENT_RULE__GET]: isSettlementPaymentRuleAuthorized,
      [PERMISSIONS.LEGAL_FIELD__GET]: isLegalFieldAuthorized,
      [PERMISSIONS.DEBTOR_USER_DEFINED_FIELD__GET]: isDebtorUserDefinedFieldAuthorized,
      [PERMISSIONS.ACCOUNT_USER_DEFINED_FIELD__GET]: isAccountUserDefinedFieldAuthorized,
      [PERMISSIONS.WORKFLOW__GET]: isWorkflowAuthorized,
      [PERMISSIONS.DEBTOR_TAG__GET]: isDebtorTagAuthorized,
      [PERMISSIONS.DEBTOR_MERGE_GROUP_RULE__GET]: isDebtorMergeGroupAuthorized,
      [PERMISSIONS.DEBTOR_MERGE_GROUP__GET]: isDebtorMergeGroupRuleAuthorized,
      [PERMISSIONS.ACCOUNT_BULK_ACTION__GET]: isAccountBulkActionAuthorized,
      [PERMISSIONS.DISPUTE__GET]: isDisputesAuthorized,
      [PERMISSIONS.DISPUTE_CATEGORY_TAG__GET]: isDisputeCategoryTagAuthorized,
      [PERMISSIONS.COMMUNICATION_RULE__GET]: isCommunicationRuleAuthorized,
      [PERMISSIONS.WORKLIST__GET]: isWorklistsAuthorized,
      [PERMISSIONS.EMPLOYER__GET]: isSettingsEmployersAuthorized,
      [PERMISSIONS.REPORT__GET]: isReportAuthorized,
      [PERMISSIONS.PAYMENT__GET]: isPaymentsAuthorized,
    },
  } = useAuthorizations();

  const {
    data: [hasComplianceFeature],
  } = useFeatureFlags(["compliance"]);

  /*
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const appMenuSlice = useSelector(selectAppMenuSlice);
  const navigatePage = (key) => {
    const { _, __, path } = PATH_TO_KEYS_MAP[key];
    navigate(path);
  };
  const getKeysForPathName = (key) => {
    return getKeysForPathName[key];
  };
  const onItemSelect = ({ item, key, keyPath, domEvent }) => {
    navigatePage(key);
  };
*/
  const items = [
    // getItem("Overview", "overview", <DashboardOutlined />), hide for now as it's not part of MVP
    isAccountWorklistAuthorized && getItem(<Link to="/accounts">Accounts</Link>, "accounts"),
    (isDataImportAuthorized ||
      isReportAuthorized ||
      isAccountBulkActionAuthorized ||
      isUserManagementAuthorized ||
      isInvoicesAuthorized ||
      isDisputesAuthorized ||
      isPaymentsAuthorized) &&
      getItem(
        "Admin",
        "admin",
        <TeamOutlined />,
        [
          // getItem("Events", "events"), hide for now as it's not part of MVP
          isReportAuthorized && getItem(<Link to="/reports">Reports</Link>, "reports"),
          isAccountBulkActionAuthorized &&
            getItem(<Link to="/bulk-actions">Bulk Actions</Link>, "bulk-actions"),
          isDataImportAuthorized &&
            getItem(<Link to="/data-import">Data Import</Link>, "data-import"),
          isInvoicesAuthorized && getItem(<Link to="/invoices">Invoices</Link>, "invoices"),
          isUserManagementAuthorized &&
            getItem(<Link to="/user-management">User Management</Link>, "user-management"),
          isDisputesAuthorized && getItem(<Link to="/disputes">Disputes</Link>, "disputes"),
          isWorklistsAuthorized &&
            getItem(<Link to="/worklists">Worklists</Link>, "worklists", null, null, "subMenu"),
          isPaymentsAuthorized &&
            getItem(<Link to="/payments">Payments</Link>, "payments", null, null, "subMenu"),
        ],
        [],
      ),
    (isAccountAssignmentConfigurationAuthorized ||
      isPriorityQueueConfigurationAuthorized ||
      isCommunicationSequenceEnabled ||
      isWorkflowAuthorized) &&
      getItem("Automation", "automation", <TeamOutlined />, [
        isAccountAssignmentConfigurationAuthorized &&
          getItem(<Link to="/assign">Account Assignment</Link>, "assign"),
        isPriorityQueueConfigurationAuthorized &&
          getItem(<Link to="/priority">Priority Accounts</Link>, "priority"),
        isCommunicationSequenceEnabled &&
          getItem(<Link to="/sequences">Communication Sequences</Link>, "sequences"),
        isWorkflowAuthorized && getItem(<Link to="/workflows">Workflows</Link>, "workflows"),
      ]),
    (isSettingsCreditReportingAuthorized ||
      isSettingsLetterTemplatesAuthorized ||
      isDebtorTagAuthorized ||
      isDebtorMergeGroupAuthorized ||
      isDebtorMergeGroupRuleAuthorized ||
      isCreditorTagAuthorized ||
      isDisputeCategoryTagAuthorized ||
      isFeePlanRuleAuthorized ||
      isLegalFieldAuthorized ||
      isDebtorUserDefinedFieldAuthorized ||
      isAccountUserDefinedFieldAuthorized ||
      isSettlementPaymentRuleAuthorized) &&
      getItem(<Link to="/settings">Settings</Link>, "settings", <SettingOutlined />),
    (isCreditorsAuthorized ||
      isForwardingAgenciesAuthorized ||
      isSettingsCourtsOfLawAuthorized ||
      isSettingsLawFirmsAuthorized ||
      isSettingsEmployersAuthorized) &&
      getItem("Organization", "organization", <TeamOutlined />, [
        isCreditorsAuthorized && getItem(<Link to="/creditors">Clients</Link>, "creditors"),
        isForwardingAgenciesAuthorized &&
          getItem(
            <Link to="/forwarding-entities">Forwarding Entities</Link>,
            "forwarding-entities",
          ),
        isSettingsEmployersAuthorized &&
          getItem(<Link to="/employers">Employers</Link>, "employers"),
        getItem("Legal", "legal", <TeamOutlined />, [
          isSettingsCourtsOfLawAuthorized &&
            getItem(<Link to="/courts-of-law">Courts of Law</Link>, "courts-of-law"),
          isSettingsLawFirmsAuthorized &&
            getItem(<Link to="/law-firms">Law Firms</Link>, "law-firms"),
        ]),
      ]),
    isDocumentCenterAuthorized &&
      getItem(<Link to="/document-center">Document Center</Link>, "document-center"),

    hasComplianceFeature &&
      isCommunicationRuleAuthorized && // Hide this menu if no permissions to CommRules since there'd be 0 items.
      getItem(
        "Compliance",
        "compliance",
        <TeamOutlined />,
        [
          isCommunicationRuleAuthorized &&
            getItem(
              <Link to="/communication-limits">Communication Limits</Link>,
              "communication-limits",
            ),
        ],
        [],
      ),
  ];

  // const { currentMenuKey, parentMenuKey } = appMenuSlice;
  return (
    <Menu
      // onClick={onItemSelect}
      theme="dark"
      mode="horizontal"
      // selectedKeys={parentMenuKey.concat(currentMenuKey)}
      // TODO: Can eventually add functionality to "Open current submenu only"
      // See https://ant.design/components/menu/#components-menu-demo-sider-current
      // onOpenChange={onOpenChange}
      //
      // HACK: For now, we will have the admin and accounts menus be always open
      // openKeys={parentMenuKey.concat(currentMenuKey).concat(["admin", "accounts"])}
      // defaultSelectedKeys={["admin", "accounts"]}
      // defaultOpenKeys={["admin", "accounts"]}
      items={items}
    />
  );
}

export default AppMenu;
