import { Checkbox, Form, Radio, Select } from "antd";
import { useFetchCommunicationMethodsQuery } from "features/communicationMethodsTable/communicationMethodsAPI";
import { useFetchForwardingEntitiesQuery } from "features/forwardingEntities/forwardingEntitiesAPI";
import {
  collectorsWithRolesSelector,
  useFetchAgencyWorkflowStates,
  useFetchCollectorsQuery,
} from "features/home/agencyPortal/homeAPI";
import { collectorFullName } from "features/home/utils";
import { useFetchProductOfferingsQuery } from "features/productOfferings/productOfferingsAPI";
import { useFetchCommunicationSequencesQuery } from "features/sequencesTable/communicationSequenceAPI";
import styled from "styled-components";

const StyledHeader = styled.h4`
  font-weight: 600;
  margin-top: 0px;
  margin-bottom: 8px;
`;

const StyledCard = styled.div`
  margin-bottom: 32px;
  padding-top: 8px;
`;

const StyledSelect = styled(Select)`
  width: 200px;
  margin-right: 4px;
`;

export default function AccountOperationsStep() {
  const form = Form.useFormInstance();
  const { data: forwardingEntities = [], isLoading: isForwardEntitiesLoading } =
    useFetchForwardingEntitiesQuery();
  const { data: communicationSequences = [], isLoading: isCommunicationSequencesLoading } =
    useFetchCommunicationSequencesQuery({
      orderBy: "-id",
    });
  const { data: communicationMethods = [], isLoading: isCommunicationMethodsLoading } =
    useFetchCommunicationMethodsQuery();
  const { data: productOfferings = [], isLoading: isProductOfferingsLoading } =
    useFetchProductOfferingsQuery();
  const { data: defaultWorkflowStates = [], isLoading: isDefaultWorkflowStatesLoading } =
    useFetchAgencyWorkflowStates();
  const { collectorsWithRoles: collectors = [], isLoading: isCollectorsWithRolesLoading } =
    useFetchCollectorsQuery(undefined, {
      selectFromResult: (result) => ({
        ...result,
        collectorsWithRoles: collectorsWithRolesSelector(result),
      }),
    });

  const filterOption = (inputValue, option) => {
    const fullOptionText = option.label;
    return fullOptionText?.toLowerCase().includes(inputValue?.toLowerCase());
  };

  // If delete is unchecked, clear all other fields
  const onDeleteAccountsChange = (e) => {
    const value = e.target.checked;

    if (value) {
      form.setFieldValue("operations", { delete: true });
    }
  };

  return (
    <StyledCard>
      <StyledHeader>Apply one or more actions to the accounts in bulk: </StyledHeader>
      <Form.Item valuePropName="checked" name={["operations", "delete"]}>
        <Checkbox onChange={onDeleteAccountsChange}>Delete Accounts</Checkbox>
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prev, curr) => prev?.operations?.delete !== curr?.operations?.delete}
      >
        {({ getFieldValue }) => {
          const deleteValue = getFieldValue(["operations", "delete"]);
          return (
            <>
              <Form.Item
                name={["operations", "changeStatus", "workflowStateId"]}
                label="Change Status"
              >
                <StyledSelect
                  popupMatchSelectWidth={false}
                  placeholder="Select Status..."
                  filterOption={filterOption}
                  options={defaultWorkflowStates.map((state) => ({
                    label: [state.code, state.name].join(" - "),
                    value: state.id,
                    title: state.description,
                  }))}
                  loading={isDefaultWorkflowStatesLoading}
                  disabled={deleteValue}
                />
              </Form.Item>
              <Form.Item
                name={["operations", "assignToCollector", "id"]}
                label="Assign to Collector"
              >
                <StyledSelect
                  showSearch
                  filterOption={(input, option) =>
                    (option.label ?? "").toLowerCase().includes(input.toLowerCase())
                  }
                  popupMatchSelectWidth={false}
                  options={[
                    {
                      label: "Unassigned",
                      value: null,
                    },
                    ...collectors.map((collector) => ({
                      label: collectorFullName(collector),
                      value: collector.id,
                    })),
                  ]}
                  placeholder="Select one..."
                  loading={isCollectorsWithRolesLoading}
                  disabled={deleteValue}
                />
              </Form.Item>
              <Form.Item
                name={["operations", "assignToForwardingEntity", "id"]}
                label="Assign Forwarding Entity"
              >
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    (option.label ?? "").toLowerCase().includes(input.toLowerCase())
                  }
                  placeholder="Select one..."
                  popupMatchSelectWidth={false}
                  options={[
                    {
                      label: "Unassigned",
                      value: null,
                    },
                    ...forwardingEntities.map((forwardingEntity) => ({
                      label: forwardingEntity.name,
                      value: forwardingEntity.id,
                    })),
                  ]}
                  loading={isForwardEntitiesLoading}
                  disabled={deleteValue}
                />
              </Form.Item>
              <Form.Item
                name={["operations", "sendLetter", "communicationMethodId"]}
                label="Letter Type"
              >
                <StyledSelect
                  placeholder="Select one.."
                  options={communicationMethods
                    .filter((communicationMethod) => communicationMethod.medium.includes("letter"))
                    .map((each) => ({
                      label: each.name,
                      value: each.id,
                    }))}
                  disabled={deleteValue}
                />
              </Form.Item>
              <Form.Item
                name={["operations", "sendText", "communicationMethodId"]}
                label="SMS Type"
              >
                <StyledSelect
                  placeholder="Select one.."
                  showSearch
                  filterOption={filterOption}
                  options={communicationMethods
                    .filter((communicationMethod) => communicationMethod.medium === "text")
                    .map((each) => ({
                      label: each.name,
                      value: each.id,
                    }))}
                  disabled={deleteValue}
                  loading={isCommunicationMethodsLoading}
                />
              </Form.Item>
              <Form.Item
                name={["operations", "assignToCommunicationSequence", "id"]}
                label="Assign Communication Sequence"
              >
                <StyledSelect
                  placeholder="Select one.."
                  options={communicationSequences.map((each) => ({
                    label: each.name,
                    value: each.id,
                  }))}
                  loading={isCommunicationSequencesLoading}
                  disabled={deleteValue}
                />
              </Form.Item>
              <Form.Item
                name={["operations", "changeProductOffering", "productOfferingId"]}
                label="Change Product Offering"
              >
                <StyledSelect
                  placeholder="Select one..."
                  options={productOfferings.map((each) => ({
                    label: each.name,
                    value: each.id,
                  }))}
                  loading={isProductOfferingsLoading}
                  disabled={deleteValue}
                />
              </Form.Item>
              <Form.Item
                name={["operations", "suppressFromCreditReporting", "isSkipCreditReporting"]}
                label="Suppress from Credit Reporting?"
                tooltip={
                  <p>If "Yes" is selected, the account will be suppressed from Credit Reporting.</p>
                }
              >
                <Radio.Group>
                  <Radio value>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name={["operations", "deleteFromCreditReporting", "isDeleteFromCreditReport"]}
                label="Delete from Credit Reporting?"
                tooltip={
                  <p>
                    If "Yes" is selected, the account which may have been previously reported will
                    be credit reported as a 'DA' to signify a 'Delete Account'.
                  </p>
                }
              >
                <Radio.Group>
                  <Radio value>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </>
          );
        }}
      </Form.Item>
    </StyledCard>
  );
}
