import { Form, Input, Modal, Select, Skeleton, message } from "antd";
import { formatError } from "common/redux/middleware/queryErrorLogger";
import { useFetchAgencyQuery } from "features/basicInfo/agencyPortal/agencySettingsAPI";
import { useFetchSmsTemplatesQuery } from "features/bulkActions/textTemplatesAPI";
import {
  useCreateCommunicationMethodMutation,
  useUpdateCommunicationMethodMutation,
} from "features/communicationMethodsTable/communicationMethodsAPI";
import { EMAIL_PROVIDERS } from "features/emailProviders/constants";
import { useGetEmailTemplatesQuery } from "features/emailTemplates/emailTemplatesAPI";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import { useFetchLetterTemplatesQuery } from "features/letterTemplates/letterTemplatesAPI";
import { useEffect } from "react";
import styled from "styled-components";

const StyledForm = styled(Form)`
  margin-top: 12px;
  max-width: 400px;
`;

const SMS_PROVIDERS = [
  {
    label: "Solutions By Text",
    value: "solutions_by_text",
  },
];

const LETTER_PROVIDERS = [
  {
    label: "Hatteras",
    value: "hatteras",
  },
  {
    label: "Revspring",
    value: "revspring",
  },
  {
    label: "DMA",
    value: "dma",
  },
  {
    label: "ICGroup",
    value: "icgroup",
  },
  {
    label: "Renkim",
    value: "renkim",
  },
];

const filterOption = (inputValue, option) => {
  const fullOptionText = option.label;
  return fullOptionText?.toLowerCase().includes(inputValue?.toLowerCase());
};

export default function AddMethodModal({
  title,
  open,
  onOk,
  onCancel,
  selectedCommunicationMethod = undefined,
}) {
  const [form] = Form.useForm();
  const isNew = !selectedCommunicationMethod;

  const { data: constants = { smsMessageTypes: [], phoneStatuses: [], phoneTypes: [] } } =
    useFetchBackendConstantsQuery();
  const [createCommunicationMethod, { isLoading: isCreateMethodLoading }] =
    useCreateCommunicationMethodMutation();
  const [updateCommunicationMethod, { isLoading: isUpdateMethodLoading }] =
    useUpdateCommunicationMethodMutation();
  const { data: agency = {}, isLoading: isAgencyLoading } = useFetchAgencyQuery();
  const { data: emailTemplates = [] } = useGetEmailTemplatesQuery(
    {},
    {
      skip: !agency.isEmailEnabled,
    },
  );
  const { data: smsTemplates = [] } = useFetchSmsTemplatesQuery(
    {},
    {
      skip: !agency.primarySmsProvider,
    },
  );
  const { data: letterTemplates = [] } = useFetchLetterTemplatesQuery();

  const isActionInProgress = isCreateMethodLoading || isUpdateMethodLoading;

  const emailTemplateOptions = emailTemplates.map((template) => ({
    label: template.name,
    value: template.id,
  }));

  const letterTemplateOptions = letterTemplates.map((template) => ({
    label: template.filename,
    value: template.id,
  }));

  // Filter out invoice_check, invoice, legal_filings, dispute letter templates from in-house letter templates
  const inHouseLetterTemplateOptions = letterTemplates
    .filter(
      (template) =>
        !["invoice_check", "invoice", "legal_filings", "dispute"].includes(template.type),
    )
    .map((template) => ({
      label: template.filename,
      value: template.id,
    }));

  const letterOptions = (agency.letterCodes ?? []).map((code) => ({
    label: code,
    value: code,
  }));

  const mediumOptions = [
    { label: "3rd-party letter", value: "third_party_letter" },
    ...(agency.isEmailEnabled ? [{ label: "Email", value: "email" }] : []),
    { label: "In-house letter", value: "in_house_letter" },
    {
      label: "Text",
      value: "text",
    },
  ];

  const save = async () => {
    const fields = await form.validateFields();

    const action = isNew
      ? createCommunicationMethod({ ...fields })
      : updateCommunicationMethod({
          ...fields,
          communicationMethodId: selectedCommunicationMethod.id,
        });
    const result = await action;

    if ("error" in result) {
      form.setFields(formatError(result.error));
    }

    return result;
  };

  const onSubmit = async () => {
    const result = await save();
    if ("data" in result) {
      const successMessage = isNew
        ? "Communication method added successfully!"
        : "Communication method updated successfully!";
      message.success(successMessage);
    }

    if ("data" in result) {
      form.resetFields();
      onOk();
    }
  };

  useEffect(() => {
    if (selectedCommunicationMethod) {
      form.setFieldsValue(selectedCommunicationMethod);
    } else {
      form.resetFields();
    }
  }, [form, selectedCommunicationMethod]);

  const initialValues = {
    medium: "third_party_letter",
    letterTemplateIds:
      selectedCommunicationMethod?.medium === "in_house_letter"
        ? selectedCommunicationMethod?.letterTemplateIds?.[0]
        : null,
    provider: agency.letterProvider,
    smsPhoneTypes: selectedCommunicationMethod?.smsPhoneTypes ?? [],
    smsPhoneStatuses: selectedCommunicationMethod?.smsPhoneStatuses ?? [],
  };

  const onCommunicationMediumChange = (value) => {
    form.resetFields(["externalEmailTemplateId", "letterCode"]);
    form.setFieldValue(
      "provider",
      {
        third_party_letter: agency.letterProvider,
        email: agency.emailProvider,
      }[value] ?? null,
    );
  };

  const onProviderChange = () => {
    form.resetFields(["externalEmailTemplateId", "letterCode"]);
  };

  return (
    <Modal
      maskClosable={false}
      title={title}
      open={open}
      onOk={onSubmit}
      onCancel={onCancel}
      okButtonProps={{
        loading: isActionInProgress,
      }}
    >
      {isAgencyLoading ? (
        <Skeleton active />
      ) : (
        <StyledForm
          form={form}
          layout="vertical"
          validateMessages={{ required: "This is a required field" }}
          initialValues={initialValues}
        >
          <Form.Item name="id" hidden>
            <Input hidden />
          </Form.Item>
          <Form.Item
            name="name"
            label="Communication Name"
            rules={[{ required: true, message: "This field is required." }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name={["medium"]} label="Communication Medium">
            <Select
              allowClear
              popupMatchSelectWidth={false}
              onChange={onCommunicationMediumChange}
              placeholder="Select communication medium..."
              options={mediumOptions}
              filterOption={filterOption}
              showSearch
            />
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => prevValues.medium !== currentValues.medium}
          >
            {({ getFieldValue }) => {
              const medium = getFieldValue("medium");
              if (!medium) return null;
              if (medium !== "in_house_letter") {
                const options = {
                  third_party_letter: LETTER_PROVIDERS,
                  email: EMAIL_PROVIDERS,
                  text: SMS_PROVIDERS,
                }[medium];

                const provider = {
                  third_party_letter: agency.letterProvider,
                  email: agency.emailProvider,
                }[medium];

                return (
                  <>
                    <Form.Item
                      name="provider"
                      label="Provider"
                      rules={[{ required: !provider, message: "Select Provider" }]}
                    >
                      {provider || (
                        <Select
                          popupMatchSelectWidth={false}
                          placeholder="Select one..."
                          options={options}
                          onChange={onProviderChange}
                          showSearch
                          filterOption={filterOption}
                        />
                      )}
                    </Form.Item>
                    {medium === "text" && (
                      <>
                        <Form.Item
                          name="smsMessageType"
                          label="SMS Type"
                          rules={[{ required: true, message: "Select an SMS Type" }]}
                        >
                          <Select
                            popupMatchSelectWidth={false}
                            placeholder="Select one..."
                            options={constants.smsMessageTypes.map((type) => ({
                              label: type.display,
                              value: type.value,
                            }))}
                            showSearch
                            filterOption={filterOption}
                          />
                        </Form.Item>
                        <Form.Item
                          noStyle
                          shouldUpdate={(prevValues, currentValues) =>
                            prevValues.smsMessageType !== currentValues.smsMessageType
                          }
                        >
                          {() => {
                            const smsMessageType = getFieldValue("smsMessageType");
                            if (smsMessageType === "send_message") {
                              return (
                                <>
                                  <Form.Item
                                    name="externalSmsTemplateId"
                                    label="Template"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Select a template",
                                      },
                                    ]}
                                  >
                                    <Select
                                      allowClear
                                      popupMatchSelectWidth={false}
                                      options={smsTemplates.map((template) => ({
                                        label: template.name,
                                        value: template.id,
                                      }))}
                                      showSearch
                                      filterOption={filterOption}
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    name="smsPhoneTypes"
                                    label="Phone Types"
                                    rules={[
                                      {
                                        required: true,
                                      },
                                    ]}
                                  >
                                    <Select
                                      allowClear
                                      mode="multiple"
                                      popupMatchSelectWidth={false}
                                      options={constants.phoneTypes.map(({ display, value }) => ({
                                        value,
                                        label: display,
                                      }))}
                                      showSearch
                                      filterOption={filterOption}
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    name="smsPhoneStatuses"
                                    label="Phone Status"
                                    rules={[
                                      {
                                        required: true,
                                      },
                                    ]}
                                  >
                                    <Select
                                      allowClear
                                      mode="multiple"
                                      popupMatchSelectWidth={false}
                                      options={constants.phoneStatuses.map(
                                        ({ display, value }) => ({
                                          value,
                                          label: display,
                                        }),
                                      )}
                                      showSearch
                                      filterOption={filterOption}
                                    />
                                  </Form.Item>
                                </>
                              );
                            }

                            return (
                              <>
                                <Form.Item
                                  name="smsPhoneTypes"
                                  label="Phone Types"
                                  rules={[
                                    {
                                      required: true,
                                    },
                                  ]}
                                >
                                  <Select
                                    allowClear
                                    mode="multiple"
                                    popupMatchSelectWidth={false}
                                    options={constants.phoneTypes.map(({ display, value }) => ({
                                      value,
                                      label: display,
                                    }))}
                                    showSearch
                                    filterOption={filterOption}
                                  />
                                </Form.Item>
                                <Form.Item
                                  name="smsPhoneStatuses"
                                  label="Phone Status"
                                  rules={[
                                    {
                                      required: true,
                                    },
                                  ]}
                                >
                                  <Select
                                    allowClear
                                    mode="multiple"
                                    popupMatchSelectWidth={false}
                                    options={constants.phoneStatuses.map(({ display, value }) => ({
                                      value,
                                      label: display,
                                    }))}
                                    showSearch
                                    filterOption={filterOption}
                                  />
                                </Form.Item>
                              </>
                            );
                          }}
                        </Form.Item>
                      </>
                    )}
                  </>
                );
              }
              if (medium === "in_house_letter") {
                return (
                  <Form.Item
                    name="letterTemplateIds"
                    label="Template"
                    rules={[{ required: true, message: "Select Letter Template" }]}
                  >
                    <Select
                      mode="multiple"
                      popupMatchSelectWidth={false}
                      placeholder="Select one..."
                      options={inHouseLetterTemplateOptions}
                      showSearch
                      filterOption={filterOption}
                    />
                  </Form.Item>
                );
              }
            }}
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.medium !== currentValues.medium ||
              prevValues.provider !== currentValues.provider
            }
          >
            {({ getFieldValue }) => {
              const medium = getFieldValue("medium");
              const provider = getFieldValue("provider");

              if (medium === "third_party_letter") {
                return (
                  <Form.Item
                    name="letterCode"
                    label="Letter Code"
                    rules={[{ required: true, message: "Select Letter Code" }]}
                  >
                    <Select
                      loading={isAgencyLoading}
                      popupMatchSelectWidth={false}
                      placeholder="Select one..."
                      options={letterOptions}
                      disabled={!provider}
                      showSearch
                      filterOption={filterOption}
                    />
                  </Form.Item>
                );
              }

              if (medium === "email") {
                return (
                  <div>
                    <Form.Item
                      name="externalEmailTemplateId"
                      label="Body Template"
                      rules={[{ required: true, message: "Select Email Template" }]}
                    >
                      <Select
                        popupMatchSelectWidth={false}
                        placeholder="Select one..."
                        options={emailTemplateOptions}
                        disabled={!provider}
                        filterOption={filterOption}
                        showSearch
                      />
                    </Form.Item>
                    <Form.Item
                      name="letterTemplateIds"
                      label="Attachment Templates"
                      rules={[{ required: false, message: "Select Letter Templates" }]}
                    >
                      <Select
                        mode="multiple"
                        popupMatchSelectWidth={false}
                        placeholder="Select..."
                        options={letterTemplateOptions}
                        disabled={!provider}
                        filterOption={filterOption}
                        showSearch
                      />
                    </Form.Item>
                  </div>
                );
              }

              // default case
              return null;
            }}
          </Form.Item>

          <Form.Item name="notes" label="Notes">
            <Input />
          </Form.Item>
        </StyledForm>
      )}
    </Modal>
  );
}
