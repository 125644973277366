import { Checkbox, Divider, Form, Modal, Row, Select, Table, message } from "antd";
import { DATE_FORMAT } from "common/constants";
import { AktDateRangePicker } from "components/aktDatePicker";
import { FormWizard } from "components/formWizard";
import {
  useFetchCreditorSummariesQuery,
  useFetchCreditorTagsQuery,
} from "features/creditors/agencyPortal/creditorsAPI";
import {
  useBulkCreateInvoicesMutation,
  useBulkPreviewInvoicesMutation,
} from "features/invoices/invoiceAPI";
import moment from "moment-timezone";
import { useMemo, useState } from "react";
import styled from "styled-components";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";

const StyledDivider = styled(Divider)`
  margin-top: 0;
  margin-bottom: 0;
`;

const StyledTable = styled(Table)`
  cursor: pointer;
  margin-bottom: 12px;
  margin-top: 12px;
  width: 100%;
`;

const StyledHeader = styled.h4`
  font-weight: 600;
  margin-top: 0px;
  margin-bottom: 8px;
`;

const StyledSpan = styled.span`
  margin-bottom: 24px;
  display: block;
  font-size: 13px;
`;

const StyledSelect = styled(Select)`
  width: 100%;
`;

const StyledCard = styled.div`
  margin-bottom: 32px;
  padding-top: 8px;
`;

const FilterTitle = styled.div`
  margin: 0 0 8px;
`;

const StyledRow = styled(Row)`
  display: flex;
  align-items: baseline;
  width: 100%;
`;

const WithFormItemStyledRow = styled(StyledRow)`
  margin-bottom: 24px;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-left: 16px;
`;

const INITIAL_SELECT_ALL_PAYMENT_CATEGORIES = true;
const INITIAL_SELECT_ALL_PAYMENT_METHOD_TYPES = true;
const INITIAL_SELECT_ALL_TRANSACTION_TYPES = true;
const INITIAL_SELECT_ALL_CREDITORS = true;

function BulkCreateInvoiceModal({ title, open, onCancel, onOk }) {
  const {
    data: constants = { paymentCategories: [], paymentMethodTypes: [], transactionTypes: [] },
    isLoading: isConstantsLoading,
  } = useFetchBackendConstantsQuery();
  const [bulkCreateInvoices, { isLoading }] = useBulkCreateInvoicesMutation();
  const [bulkPreviewInvoices] = useBulkPreviewInvoicesMutation();
  const { data: creditors } = useFetchCreditorSummariesQuery();
  const { data: creditorTags } = useFetchCreditorTagsQuery();
  const [allPaymentCategoriesSelected, setAllPaymentCategoriesSelected] = useState(
    INITIAL_SELECT_ALL_PAYMENT_CATEGORIES,
  );
  const [allPaymentMethodTypesSelected, setAllPaymentMethodTypesSelected] = useState(
    INITIAL_SELECT_ALL_PAYMENT_METHOD_TYPES,
  );
  const [allTransactionTypesSelected, setAllTransactionTypesSelected] = useState(
    INITIAL_SELECT_ALL_TRANSACTION_TYPES,
  );
  const [allCreditorsSelected, setAllCreditorsSelected] = useState(INITIAL_SELECT_ALL_CREDITORS);
  const [selectedCreditors, setSelectedCreditors] = useState([]);

  const creditorOptions = useMemo(
    () =>
      creditors?.map((creditor) => ({
        value: creditor.id,
        label: creditor.code ? `${creditor.name} (${creditor.code})` : creditor.name,
      })),
    [creditors],
  );

  const creditorTagOptions = useMemo(
    () =>
      creditorTags?.map((tag) => ({
        value: tag.id,
        label: tag.name,
      })),
    [creditorTags],
  );

  const onDone = async ({ step0, step1, step2 }) => {
    const {
      isIncludeAllPaymentCategories,
      paymentCategories,
      isIncludeAllPaymentMethodTypes,
      paymentMethodTypes,
      isIncludeAllTransactionTypes,
      transactionTypes,
      includeCreditorIds,
      includeCreditorTagIds,
      excludeCreditorIds,
      excludeCreditorTagIds,
    } = step0;

    const {
      picker: [startDate, endDate],
    } = step2;

    const creditorIds = selectedCreditors.map((creditor) => creditor.id);

    const result = await bulkCreateInvoices({
      startDate,
      endDate,
      paymentCategories: isIncludeAllPaymentCategories ? null : paymentCategories,
      paymentMethodTypes: isIncludeAllPaymentMethodTypes ? null : paymentMethodTypes,
      transactionTypes: isIncludeAllTransactionTypes ? null : transactionTypes,
      creditorIds,
    });
    if ("error" in result) {
      message.error("Failed to add invoices!");
      return result;
    }
    message.success("Invoices added successfully!");
    onOk();
  };

  const filterOption = (inputValue, option) => {
    const fullOptionText = option.label;
    return fullOptionText?.toLowerCase().includes(inputValue?.toLowerCase());
  };

  const initialValues = {
    isIncludeAllPaymentCategories: INITIAL_SELECT_ALL_PAYMENT_CATEGORIES,
    paymentCategories: null,
    isIncludeAllPaymentMethodTypes: INITIAL_SELECT_ALL_PAYMENT_METHOD_TYPES,
    paymentMethodTypes: null,
    isIncludeAllTransactionTypes: INITIAL_SELECT_ALL_TRANSACTION_TYPES,
    transactionTypes: null,
    hasPreviousBalance: true,
    containsBalance: true,
    isIncludeAllCreditors: INITIAL_SELECT_ALL_CREDITORS,
    includeCreditorIds: [],
    includeCreditorTagIds: [],
    excludeCreditorIds: [],
    excludeCreditorTagIds: [],
    picker: [
      moment().startOf("month").format(DATE_FORMAT),
      moment().endOf("month").format(DATE_FORMAT),
    ],
  };

  const steps = [
    {
      title: "Filter Invoice Items",
      form: (
        <>
          <StyledHeader>Filters</StyledHeader>
          <StyledSpan>
            Invoices will be created for clients that fulfill the specified filter criteria.
          </StyledSpan>
          <h5>Include</h5>
          <FilterTitle>Payment Categories</FilterTitle>
          <WithFormItemStyledRow align="middle" wrap={false}>
            <Form.Item name="paymentCategories" noStyle>
              <StyledSelect
                disabled={allPaymentCategoriesSelected}
                maxTagTextLength={20}
                mode="multiple"
                placeholder="Select Payment Categories..."
                filterOption={filterOption}
                options={constants.paymentCategories.invoiceable?.map(({ value, display }) => ({
                  value,
                  label: display,
                }))}
                maxTagCount="responsive"
              />
            </Form.Item>
            <Form.Item valuePropName="checked" name="isIncludeAllPaymentCategories" noStyle>
              <StyledCheckbox
                checked={allPaymentCategoriesSelected}
                onChange={(e) => {
                  const isChecked = e.target.checked;
                  setAllPaymentCategoriesSelected(isChecked);
                }}
              >
                All
              </StyledCheckbox>
            </Form.Item>
          </WithFormItemStyledRow>
          <FilterTitle>Payment Method Types</FilterTitle>
          <WithFormItemStyledRow align="middle" wrap={false}>
            <Form.Item name="paymentMethodTypes" noStyle>
              <StyledSelect
                disabled={allPaymentMethodTypesSelected}
                maxTagTextLength={20}
                mode="multiple"
                placeholder="Select Payment Method Types..."
                filterOption={filterOption}
                options={constants.paymentMethodTypes?.map(({ value, display }) => ({
                  value,
                  label: display,
                }))}
                maxTagCount="responsive"
              />
            </Form.Item>
            <Form.Item valuePropName="checked" name="isIncludeAllPaymentMethodTypes" noStyle>
              <StyledCheckbox
                checked={allPaymentMethodTypesSelected}
                onChange={(e) => {
                  const isChecked = e.target.checked;
                  setAllPaymentMethodTypesSelected(isChecked);
                }}
              >
                All
              </StyledCheckbox>
            </Form.Item>
          </WithFormItemStyledRow>
          <FilterTitle>Transaction Types</FilterTitle>
          <WithFormItemStyledRow align="middle" wrap={false}>
            <Form.Item name="transactionTypes" noStyle>
              <StyledSelect
                disabled={allTransactionTypesSelected}
                maxTagTextLength={20}
                popupMatchSelectWidth={false}
                mode="multiple"
                placeholder="Select Transaction Types..."
                filterOption={filterOption}
                options={constants.transactionTypes?.map(({ value, display }) => ({
                  value,
                  label: display,
                }))}
                maxTagCount="responsive"
              />
            </Form.Item>
            <Form.Item valuePropName="checked" name="isIncludeAllTransactionTypes" noStyle>
              <StyledCheckbox
                checked={allTransactionTypesSelected}
                onChange={(e) => {
                  const isChecked = e.target.checked;
                  setAllTransactionTypesSelected(isChecked);
                }}
              >
                All
              </StyledCheckbox>
            </Form.Item>
          </WithFormItemStyledRow>
          <FilterTitle>Clients</FilterTitle>
          <WithFormItemStyledRow align="middle" wrap={false}>
            <Form.Item name="includeCreditorIds" noStyle>
              <StyledSelect
                disabled={allCreditorsSelected}
                maxTagTextLength={20}
                mode="multiple"
                placeholder="Select Clients..."
                filterOption={filterOption}
                options={creditorOptions}
                maxTagCount="responsive"
              />
            </Form.Item>
            <Form.Item valuePropName="checked" name="isIncludeAllCreditors" noStyle>
              <StyledCheckbox
                checked={allCreditorsSelected}
                onChange={(e) => {
                  const isChecked = e.target.checked;
                  setAllCreditorsSelected(isChecked);
                }}
              >
                All
              </StyledCheckbox>
            </Form.Item>
          </WithFormItemStyledRow>
          <Form.Item name="includeCreditorTagIds" label="Client Tags">
            <StyledSelect
              disabled={allCreditorsSelected}
              maxTagTextLength={20}
              popupMatchSelectWidth={false}
              mode="multiple"
              placeholder="Select Client Tags..."
              filterOption={filterOption}
              options={creditorTagOptions}
              maxTagCount="responsive"
            />
          </Form.Item>
          <StyledDivider />
          <h5>Exclude</h5>
          <Form.Item name="excludeCreditorIds" label="Clients">
            <Select
              disabled={!allCreditorsSelected}
              maxTagTextLength={20}
              mode="multiple"
              placeholder="Select Clients..."
              filterOption={filterOption}
              options={creditorOptions}
              maxTagCount="responsive"
            />
          </Form.Item>
          <Form.Item name="excludeCreditorTagIds" label="Client Tags">
            <Select
              disabled={!allCreditorsSelected}
              maxTagTextLength={20}
              popupMatchSelectWidth={false}
              mode="multiple"
              placeholder="Select Client Tags..."
              filterOption={filterOption}
              options={creditorTagOptions}
              maxTagCount="responsive"
            />
          </Form.Item>
        </>
      ),
      async onNext({ isIncludeAllCreditors, includeCreditorIds, excludeCreditorIds }) {
        if (!isIncludeAllCreditors && !includeCreditorIds?.length) {
          return { error: new Error("Please select at least one creditor!") };
        }

        if (isIncludeAllCreditors && excludeCreditorIds?.length >= creditors?.length) {
          return { error: new Error("All creditors were excluded!") };
        }

        if (
          !isIncludeAllCreditors &&
          includeCreditorIds?.length > 0 &&
          excludeCreditorIds?.length > 0
        ) {
          return { error: new Error("Cannot include and exclude creditors!") };
        }

        const allIds = creditors.map((creditor) => creditor.id);
        const includedCreditorIds = isIncludeAllCreditors ? allIds : includeCreditorIds;
        const diff = includedCreditorIds?.filter((id) => !excludeCreditorIds?.includes(id));
        if (!diff?.length) {
          return { error: new Error("All selected creditors were excluded!") };
        }
      },
    },
    {
      title: "Balances",
      form: (
        <StyledCard>
          <StyledSpan>
            Select whether to include creditors with previous balances or current balances.
          </StyledSpan>
          <Form.Item name="hasPreviousBalance" valuePropName="checked">
            <Checkbox>Has Previous Balance</Checkbox>
          </Form.Item>
          <Form.Item name="containsBalance" valuePropName="checked">
            <Checkbox checked>Has Balance</Checkbox>
          </Form.Item>
        </StyledCard>
      ),
    },
    {
      title: "Choose Dates",
      form: (
        <StyledCard>
          <Form.Item rules={[{ required: true }]} label="Date Range" name="picker">
            <AktDateRangePicker picker="date" />
          </Form.Item>
        </StyledCard>
      ),
      async onNext({ picker: [startDate, endDate] }, { step0, step1 }) {
        const {
          isIncludeAllPaymentCategories,
          paymentCategories,
          isIncludeAllPaymentMethodTypes,
          paymentMethodTypes,
          isIncludeAllTransactionTypes,
          transactionTypes,
          isIncludeAllCreditors,
          includeCreditorIds,
          includeCreditorTagIds,
          excludeCreditorIds,
          excludeCreditorTagIds,
        } = step0.getFieldsValue();

        const { hasPreviousBalance, containsBalance } = step1.getFieldsValue();

        const result = await bulkPreviewInvoices({
          startDate,
          endDate,
          paymentCategories: isIncludeAllPaymentCategories ? null : paymentCategories,
          paymentMethodTypes: isIncludeAllPaymentMethodTypes ? null : paymentMethodTypes,
          transactionTypes: isIncludeAllTransactionTypes ? null : transactionTypes,
          includeCreditorIds: isIncludeAllCreditors ? [] : includeCreditorIds,
          includeCreditorTagIds,
          excludeCreditorIds: isIncludeAllCreditors ? excludeCreditorIds : [],
          excludeCreditorTagIds,
          isIncludeAllCreditors,
          hasPreviousBalance,
          containsBalance,
        });

        if ("error" in result) {
          return result;
        }

        if (!result.data?.length) {
          return { error: new Error("No creditors match the specified filters.") };
        }

        if ("data" in result) {
          setSelectedCreditors(result.data);
        }
      },
    },
    {
      title: "Preview",
      form: (
        <>
          <FilterTitle>
            <strong>{selectedCreditors?.length} client(s)</strong> match(es) the selected critera:
          </FilterTitle>
          <StyledTable
            bordered
            dataSource={selectedCreditors}
            scroll={{ x: "max-content" }}
            columns={[
              {
                title: "Client ID",
                dataIndex: "id",
                key: "id",
              },
              {
                title: "Client Name",
                dataIndex: "name",
                key: "name",
              },
              {
                title: "Client Code",
                dataIndex: "code",
                key: "code",
              },
            ]}
          />
        </>
      ),
      wrapperProps: {
        style: {
          width: "100%",
          maxWidth: "100%",
        },
      },
    },
  ];

  return (
    <Modal
      confirmLoading={isLoading || isConstantsLoading}
      maskClosable={false}
      title={title}
      open={open}
      onCancel={onCancel}
      footer={null}
      width={800}
    >
      <FormWizard steps={steps} onCancel={onCancel} onDone={onDone} initialValues={initialValues} />
    </Modal>
  );
}

export default BulkCreateInvoiceModal;
