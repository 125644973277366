import api, { transformRequest, transformResponse } from "services/api";

export const agencySettingsApi = api.enhanceEndpoints({ addTagTypes: ["Agency"] }).injectEndpoints({
  endpoints: (build) => ({
    fetchAgency: build.query({
      query: () => `/api/v1/core/agency-settings/`,
      transformResponse: (response) => transformResponse(response),
      providesTags: (result) =>
        result
          ? // successful query
            [
              { type: "Agency", id: result.id },
              { type: "Agency", id: "LIST" },
            ]
          : // an error occurred, but we still want to refetch this query when `{ type: 'Agency', id: 'LIST' }` is invalidated
            [{ type: "Agency", id: "LIST" }],
    }),
    updateAgency: build.mutation({
      query: (payload) => ({
        url: `/api/v1/core/agency-settings/update/`,
        method: "POST",
        body: transformRequest(payload),
      }),
      transformResponse: (response) => transformResponse(response),
      // Invalidates all Agency-type queries providing the `LIST` id - after all, depending of the sort order,
      invalidatesTags: [{ type: "Agency", id: "LIST" }],
    }),
  }),
});

export const { useFetchAgencyQuery, useUpdateAgencyMutation } = agencySettingsApi;
