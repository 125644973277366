import { Row } from "antd";
import TableFilter from "components/tableFilter/tableFilter";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import PaymentsIntentTable from "pages/agencyPortal/payments/paymentIntentTable";
import { useState } from "react";

const initialFilterState = {
  scheduledDate: null,
  status: {
    include: [],
    exclude: [],
  },
  isSettlement: null,
  paymentMethod: {
    min: null,
    max: null,
  },
};

function PaymentsListTab() {
  // React state to track the filters
  const { data: constants } = useFetchBackendConstantsQuery();
  const [filterStates, setFilterStates] = useState(initialFilterState);

  function setFilterProperty(name, value) {
    setFilterStates((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  }

  // This is the structure of the filters that will be displayed on the page
  const filteredStructure = [
    {
      title: "Scheduled Date",
      name: "scheduledDate",
      type: "date",
      onChange: (event) => setFilterProperty("scheduledDate", event),
    },
    {
      title: "Status",
      name: "status",
      type: "include/exclude",
      include: {
        mode: "multiple",
        onChange: (event) => {
          setFilterProperty("status", { ...filterStates.status, include: event });
        },
        options: constants?.paymentStatus?.map((d) => ({
          label: d.display,
          value: d.value,
        })),
        placeholder: "Select Status...",
      },
      exclude: {
        mode: "multiple",
        onChange: (event) => {
          setFilterProperty("status", { ...filterStates.status, exclude: event });
        },
        options: constants?.paymentStatus?.map((d) => ({
          label: d.display,
          value: d.value,
        })),
        placeholder: "Select Status...",
      },
    },
    {
      title: "Settlement",
      name: "isSettlement",
      type: "checkbox-option",
      options: [
        { label: "Settled", value: "settled" },
        { label: "Not Settled", value: "not_settled" },
      ],
      onChange: (event) =>
        setFilterProperty(
          "isSettlement",
          event.target.value && event.target.checked ? event.target.value : null,
        ),
      value: filterStates.isSettlement,
    },
    {
      title: "Payment Method",
      name: "paymentMethod",
      type: "min/max",
      min: {
        prefix: "$",
        step: "0.01",
        min: "0",
        onChange: (event) =>
          setFilterProperty("paymentMethod", { ...filterStates.paymentMethod, min: event }),
      },
      max: {
        prefix: "$",
        step: "0.01",
        min: "0",
        onChange: (event) =>
          setFilterProperty("paymentMethod", { ...filterStates.paymentMethod, max: event }),
      },
    },
  ];

  function convertStateToQueryParams() {
    /**
     * Convert the filter states to query params
     * @returns {
     *  scheduledDateStart: string,
     *  scheduledDateEnd: string,
     *  statuses: string[],
     *  statusesExcluded: string[],
     *  isSettlement: boolean,
     *  minTotalAmount: string,
     *  maxTotalAmount: string
     * } queryParams
     *
     */
    const queryParams = {};
    if (filterStates.scheduledDate) {
      const [scheduledDateStart, scheduledDateEnd] = filterStates.scheduledDate;
      if (scheduledDateStart) {
        queryParams.scheduledDateStart = scheduledDateStart;
      }
      if (scheduledDateEnd) {
        queryParams.scheduledDateEnd = scheduledDateEnd;
      }
    }

    if (filterStates.status.include.length > 0) {
      queryParams.includeStatusCodes = filterStates.status.include;
    }
    if (filterStates.status.exclude.length > 0) {
      queryParams.excludeStatusCodes = filterStates.status.exclude;
    }
    if (filterStates.isSettlement) {
      queryParams.isSettlement = filterStates.isSettlement === "settled";
    }

    if (filterStates.paymentMethod.min) {
      queryParams.minTotalAmount = filterStates.paymentMethod.min;
    }

    if (filterStates.paymentMethod.max) {
      queryParams.maxTotalAmount = filterStates.paymentMethod.max;
    }

    return queryParams;
  }

  function resetFilters() {
    setFilterStates(initialFilterState);
  }

  return (
    <Row wrap={false}>
      <TableFilter filteredStructure={filteredStructure} onReset={() => resetFilters()} />
      {/* NOTE: Looking for suggestion on the best way to debounce this */}
      <PaymentsIntentTable filterParams={convertStateToQueryParams()} />
    </Row>
  );
}
export default PaymentsListTab;
