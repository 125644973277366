import api, { transformRequest, transformResponse } from "services/api";

export const creditReportingApi = api
  .enhanceEndpoints({ addTagTypes: ["BureauSettings"] })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchBureauSettings: build.query({
        query: () => `/api/v1/core/credit-bureaus/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "BureauSettings", id })),
                { type: "BureauSettings", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'BureauSettings', id: 'LIST' }` is invalidated
              [{ type: "BureauSettings", id: "LIST" }],
      }),
      updateBureauSetting: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/credit-bureaus/${payload.id}/update/`,
          method: "POST",
          body: transformRequest(payload),
          headers: { "aktos-suppress-error": "true" },
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all BureauSettings-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [{ type: "BureauSettings", id: "LIST" }],
      }),
      deleteBureauSetting: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/credit-bureaus/${payload.id}/delete/`,
          method: "POST",
          body: transformRequest(payload),
          headers: { "aktos-suppress-error": "true" },
        }),
        // Invalidates all BureauSettings-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [{ type: "BureauSettings", id: "LIST" }],
      }),
      createBureauSetting: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/credit-bureaus/create/`,
          method: "POST",
          body: transformRequest(payload),
          headers: { "aktos-suppress-error": "true" },
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all BureauSettings-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [{ type: "BureauSettings", id: "LIST" }],
      }),
    }),
  });

export const {
  useCreateBureauSettingMutation,
  useFetchBureauSettingsQuery,
  useUpdateBureauSettingMutation,
  useDeleteBureauSettingMutation,
} = creditReportingApi;
