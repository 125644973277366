import { EditOutlined } from "@ant-design/icons";
import { Space, Table, Tooltip } from "antd";
import { camelToWords, snakeToCamelCase } from "common/utils";
import { useFetchAllBackupDatesQuery } from "features/backupDates/backupDatesAPI";
import EditBackupDateModal from "features/backupDates/editBackupDateModal";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import { useState } from "react";

export default function BackupDates() {
  const [activeRecord, setActiveRecord] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { data: backupDates, isLoading } = useFetchAllBackupDatesQuery();
  const { data: constants } = useFetchBackendConstantsQuery();
  const columns = [
    {
      title: "Date Field",
      dataIndex: "systemDateField",
      key: "systemDateField",
      render: (text) => camelToWords(snakeToCamelCase(text)),
    },
    {
      title: "Backup Date Configuration",
      children: [
        {
          title: "Date Name",
          dataIndex: "backupDateField",
          key: "backupDateField",
          render: (text) => (text ? camelToWords(snakeToCamelCase(text)) : "-"),
        },
        {
          title: "Days (+/-)",
          dataIndex: "offset",
          key: "offset",
          render: (text) => text ?? "-",
        },
        {
          title: "Is Required",
          dataIndex: "isRequired",
          key: "isRequired",
          render: (text) => (text ? "Yes" : "No"),
        },
      ],
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          <Tooltip placement="bottom" title="Edit Backup Date Configuration" key="edit">
            <EditOutlined
              key="edit"
              onClick={() => {
                setActiveRecord(record);
                setIsModalVisible(true);
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];
  return (
    <>
      <Table
        bordered
        loading={isLoading}
        columns={columns}
        dataSource={[
          ...(constants?.accountDateFields
            .filter((field) => field.value !== "upload_date") // AKT-3067: Remove upload date since it will always be hard coded to the time of upload
            .map((field) => ({
              systemDateField: field.value,
              backupDateField: backupDates?.find((date) => date.systemDateField === field.value)
                ?.backupDateField,
              offset: backupDates?.find((date) => date.systemDateField === field.value)?.offset,
              isRequired: backupDates?.find((date) => date.systemDateField === field.value)
                ?.isRequired,
            })) ?? []),
        ]}
      />
      {isModalVisible && (
        <EditBackupDateModal
          open={isModalVisible}
          backupDate={activeRecord}
          onOk={() => setIsModalVisible(false)}
          onCancel={() => setIsModalVisible(false)}
        />
      )}
    </>
  );
}
