import { ExclamationCircleOutlined, MoreOutlined } from "@ant-design/icons";
import { Tooltip, Dropdown, Button, Modal, message } from "antd";
import { PAYMENT_INTENT_STATUS } from "common/constants";
import {
  useBulkDeletePaymentIntentsMutation,
  useBulkExecutePaymentIntentsMutation,
  useBulkRefundPaymentIntentsMutation,
  useBulkVoidPaymentIntentsMutation,
} from "features/payments/paymentsAPI";
import { PERMISSIONS } from "features/permissions";
import useAuthorizations from "features/permissions/hooks/useAuthorizations";

const { confirm } = Modal;

export function PaymentIntentBulkActions({ dataSource = [] }) {
  const [deletePaymentIntents] = useBulkDeletePaymentIntentsMutation();
  const [refundPaymentIntents] = useBulkRefundPaymentIntentsMutation();
  const [voidPaymentIntents] = useBulkVoidPaymentIntentsMutation();
  const [executePaymentIntents] = useBulkExecutePaymentIntentsMutation();

  const {
    data: {
      [PERMISSIONS.PAYMENT__DELETE]: isPaymentDeleteAuthorized,
      [PERMISSIONS.PAYMENT__REVERSE]: isPaymentReversalAuthorized,
      [PERMISSIONS.PAYMENT__VOID]: isPaymentVoidAuthorized,
      [PERMISSIONS.PAYMENT__EXECUTE]: isPaymentExecuteAuthorized,
    },
  } = useAuthorizations();

  const actionItems = [
    {
      key: "reverse",
      label: "Reverse Payments",
      actionHandler: refundPaymentIntents,
      isVisible: isPaymentReversalAuthorized,
      disabled:
        dataSource.length === 0 ||
        dataSource.some(
          // Only show the option if all selected payments are executed
          (item) => item.status !== PAYMENT_INTENT_STATUS.EXECUTED,
        ),
    },
    {
      key: "markAsReversed",
      label: "Mark as Reversed (Aktos Only)",
      actionHandler: (payload) => refundPaymentIntents({ ...payload, aktosOnly: true }),
      isVisible: isPaymentReversalAuthorized,
      disabled:
        dataSource.length === 0 ||
        dataSource.some(
          // Only show the option if all selected payments are executed and not logging only
          (item) => item.status !== PAYMENT_INTENT_STATUS.EXECUTED || item.isLoggingOnly,
        ),
    },
    {
      key: "delete",
      label: "Delete Payments",
      actionHandler: deletePaymentIntents,
      isVisible: isPaymentDeleteAuthorized,
      disabled:
        // Always available for logging only payments.
        // For processed payments, refund/void attempt is required before the delete option appears.
        dataSource.length === 0 ||
        dataSource.every(
          // Only show the option if all selected payments are not refunded or voided and not logging only
          (item) =>
            !item.isLoggingOnly &&
            ![
              PAYMENT_INTENT_STATUS.REFUNDED,
              PAYMENT_INTENT_STATUS.VOIDED,
              PAYMENT_INTENT_STATUS.EXECUTE_ERROR,
              PAYMENT_INTENT_STATUS.REFUND_ERROR,
              PAYMENT_INTENT_STATUS.VOID_ERROR,
            ].includes(item.status),
        ),
    },
    {
      key: "execute",
      label: "Execute Payments",
      actionHandler: executePaymentIntents,
      isVisible: isPaymentExecuteAuthorized,
      disabled:
        dataSource.length === 0 ||
        dataSource.some(
          // Only show the option if all selected payments are new and not logging only
          (item) =>
            !isPaymentExecuteAuthorized ||
            !item.isLoggingOnly ||
            item.status !== PAYMENT_INTENT_STATUS.NEW,
        ),
    },
    {
      key: "void",
      label: "Void Payment",
      actionHandler: voidPaymentIntents,
      isVisible: isPaymentVoidAuthorized,
      disabled:
        dataSource.length === 0 ||
        dataSource.some(
          // Only show the option if all selected payments are executed
          (item) => !isPaymentVoidAuthorized || item.status !== PAYMENT_INTENT_STATUS.EXECUTED,
        ),
    },
  ];

  const handleOk = (item, key) => {
    confirm({
      title:
        key === "markAsReversed"
          ? "Are you sure you want to mark these payments as reversed (Aktos only)? Please ensure the reversal has already been processed by the external payment processor."
          : `Are you sure you want to ${key} these payments?`,
      icon: <ExclamationCircleOutlined />,
      async onOk() {
        const result = await item.actionHandler({
          paymentIntentIds: [...dataSource.map((row) => row.id)],
        });

        if ("error" in result) {
          switch (key) {
            case "markAsReversed":
              message.error("Failed to mark payments as reversed");
              break;
            default:
              message.error(`Failed to ${key} payments`);
          }
        }
        if ("data" in result) {
          switch (key) {
            case "delete":
              message.success("Payments successfully deleted");
              break;
            case "reverse":
              message.success("Payments successfully reversed");
              break;
            case "markAsReversed":
              message.success("Payments successfully marked as reversed");
              break;
            case "execute":
              message.success("Payments successfully executed");
              break;
            case "void":
              message.success("Payments successfully voided");
              break;
            default:
              message.success(`Payment successfully ${key}ed`);
          }
        }
      },
    });
  };

  const handleMenuClick = ({ item, key, keyPath, domEvent }) => {
    // This prevents navigation to invoice detail when clicking on a dropdown item
    domEvent.preventDefault();
    domEvent.stopPropagation();

    // Call the action handler defined in the items array
    handleOk(item.props, key);
  };

  return (
    <Tooltip title="Perform action on selected items">
      <Dropdown
        menu={{
          items: actionItems,
          onClick: (clickHandlerProp) => handleMenuClick(clickHandlerProp),
        }}
        trigger={["click"]}
      >
        <Button
          type="text"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <MoreOutlined />
        </Button>
      </Dropdown>
    </Tooltip>
  );
}
