import api, { transformCursorResponse, transformRequest } from "services/api";

export const paymentsAllocationApi = api
  .enhanceEndpoints({ addTagTypes: ["PaymentAllocations"] })
  .injectEndpoints({
    endpoints: (build) => ({
      getPaymentAllocationsList: build.query({
        query: (payload) => ({
          url: `/api/v1/core/payment-allocations/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformCursorResponse(response),
        providesTags: () => [{ type: "PaymentAllocations", id: "LIST" }],
      }),
      updatePaymentAllocationsFeePlan: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/payment-allocations/bulk-update/fee-plan/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        invalidatesTags: () => [{ type: "PaymentAllocations", id: "LIST" }],
      }),
    }),
  });
export const { useGetPaymentAllocationsListQuery, useUpdatePaymentAllocationsFeePlanMutation } =
  paymentsAllocationApi;
