import { Button, Form, Input, Select, Switch, message } from "antd";
import {
  useFetchAgencyQuery,
  useUpdateAgencyMutation,
} from "features/basicInfo/agencyPortal/agencySettingsAPI";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import { useCallback, useEffect } from "react";
import styled from "styled-components";

const StyledForm = styled(Form)`
  width: 600px;
`;

const StyledButton = styled(Button)`
  margin-top: 20px;
`;

export default function BasicInfo() {
  const [form] = Form.useForm();
  const [updateAgency, { isLoading: isUpdateLoading }] = useUpdateAgencyMutation();
  const { data: agencySettings, isLoading: isAgencySettingsLoading } = useFetchAgencyQuery();
  const { data: constants, isLoading: isConstantsLoading } = useFetchBackendConstantsQuery();

  useEffect(() => {
    if (agencySettings) {
      form.setFieldsValue(agencySettings);
    }
  }, [agencySettings, form]);

  const onSave = useCallback(async () => {
    const values = await form.validateFields();
    const result = await updateAgency(values);
    if ("data" in result) {
      message.success("Agency settings updated successfully");
    }
  }, [form, updateAgency]);

  const timezones = [
    { display: "UTC", value: "UTC" },
    { display: "Eastern Standard Time (EST)", value: "US/Eastern" },
    { display: "Central Standard Time (CST)", value: "US/Central" },
    { display: "Mountain Standard Time (MST)", value: "US/Mountain" },
    { display: "Pacific Standard Time (PST)", value: "US/Pacific" },
    { display: "Alaska Standard Time (AKST)", value: "US/Alaska" },
    { display: "Hawaii-Aleutian Standard Time (HST)", value: "US/Hawaii" },
    { display: "Atlantic Standard Time (AST)", value: "America/Halifax" },
    { display: "Newfoundland Standard Time (NST)", value: "America/St_Johns" },
  ];

  return (
    <StyledForm form={form} onFinish={onSave}>
      <h4>Agency Name</h4>
      <Form.Item name="name">
        <Input placeholder="Enter agency name" />
      </Form.Item>

      <h4>Operating States</h4>
      <Form.Item name="operatingStates">
        <Select
          loading={isAgencySettingsLoading || isConstantsLoading || isUpdateLoading}
          mode="multiple"
          popupMatchSelectWidth={false}
          options={constants?.states?.map(({ display, value }) => ({
            label: display,
            value,
          }))}
        />
      </Form.Item>
      <h4>Letter Codes</h4>
      <Form.Item name="letterCodes">
        <Select
          loading={isAgencySettingsLoading || isConstantsLoading || isUpdateLoading}
          mode="tags"
          popupMatchSelectWidth={false}
          options={agencySettings?.letterCodes?.map(({ value }) => ({
            label: value,
            value,
          }))}
        />
      </Form.Item>
      <h4>Timezone</h4>
      <Form.Item name="timezone">
        <Select
          loading={isAgencySettingsLoading || isConstantsLoading || isUpdateLoading}
          popupMatchSelectWidth={false}
          options={timezones?.map(({ display, value }) => ({
            label: display,
            value,
          }))}
        />
      </Form.Item>
      <h4>Collector Continuity</h4>
      <Form.Item name="isCollectorContinuityEnabled">
        <Switch
          defaultChecked={!!agencySettings?.isRequired}
          checkedChildren="Enabled"
          unCheckedChildren="Disabled"
        />
      </Form.Item>
      <StyledButton type="primary" onClick={form.submit} loading={isUpdateLoading}>
        Save
      </StyledButton>
    </StyledForm>
  );
}
