import { Result } from "antd";
import { PageHeader } from "components/pageHeader";
import AppearanceManagementTable from "features/appearance/components/appearancesTable";
import { useFetchMeQuery } from "features/auth/authAPI";
import { AuthenticationMethodsTable } from "features/authenticationMethods";
import BackupDates from "features/backupDates/backupDates";
import { useFetchAgencyQuery } from "features/basicInfo/agencyPortal/agencySettingsAPI";
import BasicInfo from "features/basicInfo/basicInfo";
import { CreditReporting } from "features/creditReporting";
import { EmailTemplatesTable } from "features/emailTemplates";
import { FeePlans } from "features/feePlans";
import { LetterTemplates } from "features/letterTemplates";
import { MergeGroups } from "features/mergeGroups";
import { PERMISSIONS } from "features/permissions";
import useAuthorizations from "features/permissions/hooks/useAuthorizations";
import ProductOfferingTable from "features/productOfferings/productOfferingsTable";
import { SettingsTabs } from "features/settingsTabs";
import { SettlementAndPaymentRules } from "features/settlementAndPaymentRules";
import { CreditorTagTable, DebtorTagTable, DisputeCategoryTagTable } from "features/tags";
import { TaxPlans } from "features/taxPlans";
import { UserDefinedFieldsTable } from "features/userDefinedFields";
import Content from "layouts/agencyPortal/dashboard/content";
import PageLayout from "layouts/agencyPortal/dashboard/pageLayout";
import { Link, Navigate, Route, Routes } from "react-router-dom";
import styled from "styled-components";

const StyledContainer = styled.div`
  margin-left: 256px;
  padding: 8px 24px;
`;
const getItem = (label, key, icon, children, type) => {
  return {
    key,
    // icon,
    children,
    label,
    type,
  };
};

function SettingsPage() {
  const { data: agency } = useFetchAgencyQuery();
  const { data: me } = useFetchMeQuery();
  const {
    data: {
      [PERMISSIONS.SETTLEMENT_PAYMENT_RULE__GET]: isSettlementPaymentRulesAuthorized,
      [PERMISSIONS.CREDIT_REPORT_SETTINGS__GET]: isSettingsCreditReportingAuthorized,
      [PERMISSIONS.LETTER_TEMPLATE__GET]: isSettingsLetterTemplatesAuthorized,
      [PERMISSIONS.DEBTOR_TAG__GET]: isDebtorTagAuthorized,
      [PERMISSIONS.CREDITOR_TAG__GET]: isCreditorTagAuthorized,
      [PERMISSIONS.DISPUTE_CATEGORY_TAG__GET]: isDisputeCategoryTagAuthorized,
      [PERMISSIONS.FEE_PLAN_RULE__GET]: isFeePlanRuleAuthorized,
      [PERMISSIONS.LEGAL_FIELD__GET]: isLegalFieldAuthorized,
      [PERMISSIONS.DEBTOR_USER_DEFINED_FIELD__GET]: isDebtorUserDefinedFieldAuthorized,
      [PERMISSIONS.ACCOUNT_USER_DEFINED_FIELD__GET]: isAccountUserDefinedFieldAuthorized,
      [PERMISSIONS.CREDITOR_USER_DEFINED_FIELD__GET]: isCreditorUserDefinedFieldAuthorized,
      [PERMISSIONS.PRODUCT_OFFERING__GET]: isProductsAuthorized,
      [PERMISSIONS.TAX_PLAN__GET]: isTaxPlanAuthorized,
    },
    isLoading,
  } = useAuthorizations();

  if (isLoading) {
    return null;
  }
  const items = [
    me.isStaff &&
      getItem(
        <Link to="/settings/basic-info">Basic Info</Link>,
        "basic-info",
        null,
        null,
        "subMenu",
      ),
    getItem(
      <Link to="/settings/backup-dates">Backup Dates</Link>,
      "backup-dates",
      null,
      null,
      "subMenu",
    ),
    isFeePlanRuleAuthorized &&
      getItem(<Link to="/settings/fee-plans">Fee Plans</Link>, "fee-plans", null, null, "subMenu"),
    isSettlementPaymentRulesAuthorized &&
      getItem(
        <Link to="/settings/payment-rules">Payment Rules</Link>,
        "payment-rules",
        null,
        null,
        "subMenu",
      ),
    isTaxPlanAuthorized &&
      getItem(<Link to="/settings/tax-plans">Tax Plans</Link>, "tax-plans", null, null, "subMenu"),
    getItem(
      <Link to="/settings/merge-groups">Merge Groups</Link>,
      "merge-groups",
      null,
      null,
      "subMenu",
    ),
    (isDebtorUserDefinedFieldAuthorized ||
      isAccountUserDefinedFieldAuthorized ||
      isLegalFieldAuthorized) &&
      getItem("User Defined Fields", "udf", null, [
        isDebtorUserDefinedFieldAuthorized &&
          getItem(
            <Link to="/settings/debtor-udf">Debtor</Link>,
            "debtor-udf",
            null,
            null,
            "subMenu",
          ),
        isAccountUserDefinedFieldAuthorized &&
          getItem(
            <Link to="/settings/account-udf">Account</Link>,
            "account-udf",
            null,
            null,
            "subMenu",
          ),
        isCreditorUserDefinedFieldAuthorized &&
          getItem(
            <Link to="/settings/creditor-udf">Client</Link>,
            "creditor-udf",
            null,
            null,
            "subMenu",
          ),
        isLegalFieldAuthorized &&
          getItem(<Link to="/settings/legal-udf">Legal</Link>, "legal-udf", null, null, "subMenu"),
      ]),
    (isSettingsLetterTemplatesAuthorized || agency?.isEmailEnabled) &&
      getItem("Templates", "templates", null, [
        isSettingsLetterTemplatesAuthorized &&
          getItem(
            <Link to="/settings/letter-templates">Letter</Link>,
            "letter",
            null,
            null,
            "subMenu",
          ),
        agency?.isEmailEnabled &&
          getItem(
            <Link to="/settings/email-templates">Email</Link>,
            "email",
            null,
            null,
            "subMenu",
          ),
      ]),
    isSettingsCreditReportingAuthorized &&
      getItem(
        <Link to="/settings/credit-reporting">Credit Reporting</Link>,
        "credit-reporting",
        null,
        null,
        "subMenu",
      ),
    isProductsAuthorized &&
      getItem(<Link to="/settings/products">Products</Link>, "products", null, null, "subMenu"),
    (isDebtorTagAuthorized || isCreditorTagAuthorized || isDisputeCategoryTagAuthorized) &&
      getItem("Tags", "tags", null, [
        isDebtorTagAuthorized &&
          getItem(
            <Link to="/settings/creditor-tags">Client</Link>,
            "creditor-tags",
            null,
            null,
            "subMenu",
          ),
        isCreditorTagAuthorized &&
          getItem(
            <Link to="/settings/debtor-tags">Debtor</Link>,
            "debtor-tags",
            null,
            null,
            "subMenu",
          ),
        isDisputeCategoryTagAuthorized &&
          getItem(
            <Link to="/settings/dispute-category-tags">Dispute Category</Link>,
            "dispute-category-tags",
            null,
            null,
            "subMenu",
          ),
      ]),
    getItem(
      <Link to="/settings/authentication-methods">Authentication Methods</Link>,
      "authentication-methods",
      null,
      null,
      "subMenu",
    ),
    me.isStaff &&
      getItem(
        <Link to="/settings/appearances">Appearances</Link>,
        "appearances",
        null,
        null,
        "subMenu",
      ),
  ].filter((item) => !!item); // Filter array of items that may contain 'false' when user doesn't have appropriate permissions

  if (items.length === 0) {
    return (
      <PageLayout>
        <Content>
          <PageHeader>Settings</PageHeader>
          <Result
            status="403"
            title="Oops!"
            subTitle="You do not have access to any settings"
            extra={<Link to="/accounts">Back Home</Link>}
          />
        </Content>
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <Content>
        <SettingsTabs items={items} />
        <StyledContainer>
          <Routes>
            <Route path="/" element={<Navigate to={`/settings/${items[0]?.key}`} replace />} />
            <Route path="/basic-info" element={<BasicInfo />} />
            <Route path="/backup-dates" element={<BackupDates />} />
            <Route path="/fee-plans" element={<FeePlans />} />
            <Route path="/payment-rules" element={<SettlementAndPaymentRules />} />
            <Route path="/tax-plans" element={<TaxPlans />} />
            <Route path="/merge-groups" element={<MergeGroups />} />
            <Route path="/credit-reporting" element={<CreditReporting />} />
            <Route path="/debtor-udf" element={<UserDefinedFieldsTable forType="debtor" />} />
            <Route path="/account-udf" element={<UserDefinedFieldsTable forType="account" />} />
            <Route path="/creditor-udf" element={<UserDefinedFieldsTable forType="client" />} />
            <Route path="/legal-udf" element={<UserDefinedFieldsTable forType="legal" />} />
            <Route path="/letter-templates" element={<LetterTemplates />} />
            <Route path="/email-templates" element={<EmailTemplatesTable />} />
            <Route path="/debtor-tags" element={<DebtorTagTable />} />
            <Route path="/creditor-tags" element={<CreditorTagTable />} />
            <Route path="/dispute-category-tags" element={<DisputeCategoryTagTable />} />
            <Route path="/products" element={<ProductOfferingTable />} />
            <Route path="/authentication-methods" element={<AuthenticationMethodsTable />} />
            <Route path="/appearances" element={<AppearanceManagementTable />} />
          </Routes>
        </StyledContainer>
      </Content>
    </PageLayout>
  );
}

export default SettingsPage;
