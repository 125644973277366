import { TableFormList } from "components/formList";
import {
  useCreateDebtorPhoneNumberMutation,
  useDeleteDebtorPhoneNumberMutation,
  useFetchDebtorPhoneNumbersQuery,
  useUpdateDebtorPhoneNumberMutation,
} from "features/debtorSider/agencyPortal/debtorSiderAPI";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import { useMemo } from "react";

export default function PhonesStep({
  debtorId = undefined,
  dataSource = undefined,
  handleDelete = undefined,
  handleSave = undefined,
}) {
  const isControlled = !!dataSource && !!handleDelete;

  const { data: constants } = useFetchBackendConstantsQuery();
  const { data: debtorPhones, isLoading } = useFetchDebtorPhoneNumbersQuery(
    {
      debtorId,
    },
    { skip: !debtorId },
  );
  const [updateDebtorPhone] = useUpdateDebtorPhoneNumberMutation();
  const [deleteDebtorPhone] = useDeleteDebtorPhoneNumberMutation();
  const [createDebtorPhone] = useCreateDebtorPhoneNumberMutation();

  const phoneTypeOptions = useMemo(() => {
    return constants?.phoneTypes
      ?.filter((type) => type.value !== "home")
      .map(({ value, display }) => ({ value, label: display }));
  }, [constants?.phoneTypes]);

  const columns = [
    {
      dataIndex: "isPrimary",
      editable: true,
      inputType: "checkbox",
      title: "Primary",
      valuePropName: "checked",
      render: (value) => (value ? "Yes" : "-"),
    },
    {
      dataIndex: "type",
      editable: true,
      inputType: "select",
      title: "Type",
      options: phoneTypeOptions,
    },
    {
      dataIndex: "number",
      editable: true,
      inputType: "text",
      title: "Phone Number",
      rules: [
        {
          required: true,
          message: "Enter Phone Number",
        },
      ],
    },
    {
      dataIndex: "permissionToCallStatus",
      editable: true,
      inputType: "select",
      title: "Consent For Phone",
      options: constants?.contactPermissionStatuses?.map((each) => ({
        label: each.display,
        value: each.value,
      })),
      render: (value) => {
        const status = constants?.contactPermissionStatuses?.find((each) => each.value === value);
        return status?.display;
      },
    },
    {
      dataIndex: "permissionToTextStatus",
      editable: true,
      inputType: "select",
      title: "Consent For SMS",
      options: constants?.contactPermissionStatuses?.map((each) => ({
        label: each.display,
        value: each.value,
      })),
      render: (value) => {
        const status = constants?.contactPermissionStatuses?.find((each) => each.value === value);
        return status?.display;
      },
    },
  ];

  const data = isControlled ? dataSource : debtorPhones;

  return (
    <TableFormList
      addText="Add Phone"
      loading={isLoading}
      defaultValues={{ type: "work", isPrimary: data.length === 0 }}
      columns={columns}
      data={isControlled ? dataSource : debtorPhones}
      onDelete={(phone) =>
        isControlled
          ? handleDelete(phone)
          : deleteDebtorPhone({
              debtorId,
              phone,
            })
      }
      onSave={(phone) => {
        if (isControlled) return handleSave(phone);

        const action = phone.id ? updateDebtorPhone : createDebtorPhone;
        return action({
          debtorId,
          phone,
        });
      }}
    />
  );
}
