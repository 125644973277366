import getAccountSchemaFields from "integrations/dromo/getAccountSchemaFields";

export default function getMultipleCreditorAccountSchemaFields(options, requiredBackupFields = []) {
  return [
    {
      key: "client_id",
      label: "Client ID (Aktos ID)",
      type: "number",
      validators: [{ validate: "require_without_all", fields: ["client_code"] }],
      description:
        "The Client ID for the account. Should be provided only if no client code is provided.",
    },
    {
      key: "client_code",
      label: "Client Code",
      type: "string",
      validators: [{ validate: "require_without_all", fields: ["client_id"] }],
      description:
        "The Client Code for the account. Should be provided only if no client ID is provided.",
    },
    {
      key: "client_cbr_class_code",
      label: "Client CBR Class Code",
      type: "select",
      validators: [{ validate: "required" }],
      selectOptions: [
        {
          value: "01",
          label: "01 - Retail",
        },
        {
          value: "02",
          label: "02 - Medical",
        },
        {
          value: "03",
          label: "03 - Oil",
        },
        {
          value: "04",
          label: "04 - Government",
        },
        {
          value: "05",
          label: "05 - Personal Services",
        },
        {
          value: "06",
          label: "06 - Insurance",
        },
        {
          value: "07",
          label: "07 - Educational",
        },
        {
          value: "08",
          label: "08 - Banking",
        },
        {
          value: "09",
          label: "09 - Rental or Leasing",
        },
        {
          value: "10",
          label: "10 - Utilities",
        },
        {
          value: "11",
          label: "11 - Cable or Cellular",
        },
        {
          value: "12",
          label: "12 - Financial",
        },
        {
          value: "13",
          label: "13 - Credit Union",
        },
        {
          value: "14",
          label: "14 - Automotive",
        },
        {
          value: "15",
          label: "15 - Check Guarantee",
        },
      ],
    },
    {
      key: "product_offering_id",
      label: "Product Offering ID (Aktos ID)",
      type: "select",
      validators: [{ validate: "required" }],
      selectOptions: options?.productOfferings?.map((offering) => ({
        value: offering.id.toString(),
        label: `${offering.id} - ${offering.name}`,
      })),
    },
    ...getAccountSchemaFields(options?.accountType, requiredBackupFields),
  ];
}
