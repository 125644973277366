import { PlusOutlined } from "@ant-design/icons";
import { Button, Table, Tag } from "antd";
import { DATE_TIME_FORMAT } from "common/constants";
import { toTitleCase } from "common/utils";
import { useUserType } from "features/auth";
import { useGetDebtorCommunicationHistoryQuery } from "features/communicationsTable/agencyPortal/communicationsTableAPI";
import CommunicationModal from "features/communicationsTable/components/communicationModal";
import NotesModal from "features/communicationsTable/components/notesModal";
import { useGetCreditorPortalDebtorCommunicationHistoryQuery } from "features/communicationsTable/creditorPortal/communicationsTableAPI";
import { useFetchDebtorContactsQuery } from "features/debtorSider/agencyPortal/debtorSiderAPI";
import { useFetchCreditorPortalDebtorContactsQuery } from "features/debtorSider/creditorPortal/debtorSiderAPI";
import {
  useFetchBackendConstantsQuery,
  useFetchCollectorsQuery,
} from "features/home/agencyPortal/homeAPI";
import { PERMISSIONS, useAuthorization } from "features/permissions";
import moment from "moment-timezone";
import { useState } from "react";
import styled from "styled-components";

const StyledTag = styled(Tag)`
  margin: 4px 4px;
`;

const StyledTable = styled(Table)`
  cursor: pointer;
  margin-top: 12px;
  & .ant-table-cell:not(th) {
    padding: 4px 8px !important;
  }
`;

const StyledButton = styled(Button)`
  padding: 0;
`;

function CommunicationsTable({ debtorId, hasConsumerConsent = false }) {
  const { isAgencyUserType } = useUserType();
  const { data: constants } = useFetchBackendConstantsQuery();
  const [notesText, setNotesText] = useState("");
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);

  const [isAddCommunicationsModalVisible, setIsAddCommunicationsModalVisible] = useState(false);
  const { data: agencyCommunicationsTableData, isLoading: isAgencyCommunicationsQueryLoading } =
    useGetDebtorCommunicationHistoryQuery(
      {
        debtorId,
      },
      { skip: !isAgencyUserType },
    );
  const {
    data: creditorPortalCommunicationsTableData,
    isLoading: isCreditorPortalCommunicationsLoading,
  } = useGetCreditorPortalDebtorCommunicationHistoryQuery(
    {
      debtorId,
    },
    { skip: isAgencyUserType },
  );
  const [communicationsTableData, isLoading] = isAgencyUserType
    ? [agencyCommunicationsTableData, isAgencyCommunicationsQueryLoading]
    : [creditorPortalCommunicationsTableData, isCreditorPortalCommunicationsLoading];

  const { data: agencyDebtorContacts } = useFetchDebtorContactsQuery(
    { debtorId },
    { skip: !isAgencyUserType },
  );
  const { data: creditorPortalDebtorContacts } = useFetchCreditorPortalDebtorContactsQuery(
    { debtorId },
    { skip: isAgencyUserType },
  );
  const debtorContacts = isAgencyUserType ? agencyDebtorContacts : creditorPortalDebtorContacts;

  const { data: collectors } = useFetchCollectorsQuery({}, { skip: !isAgencyUserType });
  const { data: isCreateCommunicationAuthorized } = useAuthorization(
    PERMISSIONS.COMMUNICATION__CREATE,
  );
  const { data: isCommunicationGetNotesAuthorized } = useAuthorization(
    PERMISSIONS.COMMUNICATION__GET_NOTES,
  );

  const handleAddCommunicationsOk = async () => {
    setIsAddCommunicationsModalVisible(false);
  };

  const handleAddCommunicationsCancel = () => {
    setIsAddCommunicationsModalVisible(false);
  };

  const onNotesModalClose = () => {
    setIsNotesModalOpen(false);
    setNotesText("");
  };

  const columns = [
    {
      title: "Start Time",
      dataIndex: "communicationStartTime",
      key: "communicationStartTime",
      render: (text, _) => (text ? moment(text).format(DATE_TIME_FORMAT) : "-"),
      sorter: (a, b) =>
        moment(a.communicationStartTime).unix() - moment(b.communicationStartTime).unix(),
    },
    {
      title: "Recipient",
      key: "Recipient",
      render: (text, record) => {
        if (record.contactType === "debtor_contact") {
          const debtorContact = debtorContacts?.find(
            (contact) => contact.id === record.debtorContact,
          );
          if (debtorContact) {
            const fullName = [
              debtorContact.firstName,
              debtorContact.middleName,
              debtorContact.lastName,
            ]
              .filter((x) => x)
              .join(" ");
            return `${fullName} (${debtorContact.relationshipToDebtor})`;
          }
        }
        return toTitleCase({ str: record.contactType, delimiter: "_" });
      },
      sorter: (a, b) => {
        return a.recipient?.toLowerCase() > b.recipient?.toLowerCase();
      },
    },
    {
      title: "Contact Method",
      dataIndex: "contactMethod",
      key: "contactMethod",
      render: (contactMethod, record) => {
        const hasPhoneNumber =
          ["phone", "text"].includes(contactMethod.toLowerCase()) && record.phoneNumber;

        let content = hasPhoneNumber ? `${contactMethod} (${record.phoneNumber})` : contactMethod;
        const communicationOrigin = record?.communicationOrigin;

        content = communicationOrigin ? `${content} (${communicationOrigin})` : content;
        content = toTitleCase({ str: content });
        return (
          <>
            <span>{content}</span>
            <br />
          </>
        );
      },
      sorter: {
        compare: (a, b) => {
          const contactMethodA = a?.contactMethod?.toUpperCase();
          const contactMethodB = b?.contactMethod?.toUpperCase();
          if (contactMethodA < contactMethodB) {
            return -1;
          }
          if (contactMethodA > contactMethodB) {
            return 1;
          }

          return 0;
        },
      },
    },
    {
      title: "Results",
      width: 400,
      render: (_, communication) => {
        const callResults = communication.callResults ?? [];
        const mailResults = communication.mailResults ?? [];
        const textResults = communication.textResults ?? [];
        const communicationResults = communication.communicationResults ?? [];
        return (
          <div>
            {communicationResults.map((result) => (
              <StyledTag color="#2db7f5" key={result}>
                {
                  constants?.communicationResults?.find(
                    (communicationResult) => communicationResult.value === result,
                  ).display
                }
              </StyledTag>
            ))}
            {mailResults.map((result) => (
              <StyledTag color="#ebb25e" key={result}>
                {
                  constants?.mailResults?.find(
                    (communicationResult) => communicationResult.value === result,
                  )?.display
                }
              </StyledTag>
            ))}
            {callResults.map((result) => (
              <StyledTag color="#2fba5b" key={result}>
                {
                  constants?.callResults?.find(
                    (communicationResult) => communicationResult.value === result,
                  )?.display
                }
              </StyledTag>
            ))}
            {textResults.map((result) => {
              const display = constants?.textResults?.find(
                (textResult) => textResult.value === result,
              )?.display;

              if (display) {
                return (
                  <StyledTag color="#2abcdc" key={result}>
                    {" "}
                    {
                      constants?.textResults?.find((textResult) => textResult.value === result)
                        ?.display
                    }{" "}
                  </StyledTag>
                );
              }
              return null;
            })}
            {!!communication.isConsentForText && <StyledTag>Consented for text</StyledTag>}
            {!!communication.isConsentForEmail && <StyledTag>Consented for email</StyledTag>}
            {!!communication.isCeaseCommunication && (
              <StyledTag color="red">Do not call anymore</StyledTag>
            )}
          </div>
        );
      },
      key: "Results",
    },
    {
      title: "Recorded At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, _) => (text ? moment(text).format(DATE_TIME_FORMAT) : "-"),
      sorter: (a, b) =>
        moment(a.communicationStartTime).unix() - moment(b.communicationStartTime).unix(),
    },
    {
      title: "Recorded By",
      dataIndex: "createdBy",
      key: "createdBy",
      render: (text, _) => {
        const collector = collectors?.find((c) => c.id === text);
        return collector?.email ?? "";
      },
    },
    {
      title: "Preferred Follow-Up Time",
      dataIndex: "preferredFollowUpTime",
      key: "preferredFollowUpTime",
      render: (text, record) =>
        record.preferredFollowUpTime
          ? moment(record.preferredFollowUpTime).format(DATE_TIME_FORMAT)
          : "-",
      sorter: (a, b) =>
        moment(a.preferredFollowUpTime).unix() - moment(b.preferredFollowUpTime).unix(),
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
      fixed: "right",
      width: 400,
      render: (text, _) => {
        if (text && text?.length > 200) {
          return (
            <span>
              {text?.substring(0, 200)}...
              <StyledButton onClick={() => setNotesText(text)} type="link">
                See Full Notes
              </StyledButton>
            </span>
          );
        }
        return text;
      },
      hidden: !isCommunicationGetNotesAuthorized,
    },
  ].filter((column) => !column.hidden);

  return (
    <>
      <NotesModal
        title="Notes"
        open={notesText !== ""}
        onClose={onNotesModalClose}
        notesText={notesText}
      />
      <CommunicationModal
        debtorId={debtorId}
        title="Add Communication"
        open={isAddCommunicationsModalVisible}
        onOk={handleAddCommunicationsOk}
        onCancel={handleAddCommunicationsCancel}
      />
      {hasConsumerConsent && isCreateCommunicationAuthorized && (
        <StyledButton
          type="link"
          onClick={() => setIsAddCommunicationsModalVisible(true)}
          icon={<PlusOutlined />}
        >
          Add Communication Log
        </StyledButton>
      )}
      <StyledTable
        loading={isLoading}
        bordered
        // @ts-ignore
        columns={columns}
        dataSource={communicationsTableData}
        scroll={{ x: "max-content" }}
      />
    </>
  );
}

export default CommunicationsTable;
