import { useFetchMeQuery } from "features/auth/authAPI";
import { MomentProvider } from "providers";
import AppearanceProvider from "providers/appearanceProvider";
import { Route, Routes } from "react-router-dom";

function getRoutes(routes) {
  return routes.map((route) => {
    return <Route key={route.path} {...route} />;
  });
}

function UserTypeRoute({ routes }) {
  const { data: me, isLoading } = useFetchMeQuery();

  // If we are still loading the user, we don't know what routes to show
  if (isLoading || !me || !me.type) {
    return null;
  }

  const userType = me.type;

  // We are not expecting any other user types
  const byRoleRoutes = {
    agency: routes.agency,
    creditor: routes.creditor,
  };

  return (
    <MomentProvider userType={userType}>
      <AppearanceProvider>
        <Routes>{getRoutes(byRoleRoutes[userType])}</Routes>
      </AppearanceProvider>
    </MomentProvider>
  );
}

export default UserTypeRoute;
