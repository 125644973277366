import { EditOutlined } from "@ant-design/icons";
import { Button, Form, Space, message } from "antd";
import { formatError } from "common/redux/middleware/queryErrorLogger";
import {
  customFieldTypeFormatFunctions,
  getCustomFieldsItems,
  udfToComponentMap,
} from "common/utils";
import { AktDatePicker } from "components/aktDatePicker";
import { DisplayComponent } from "components/formItems";
import {
  useFetchDebtorProfileQuery,
  useUpdateDebtorProfileMutation,
} from "features/accountsTable/agencyPortal/accountsTableAPI";
import { useFetchCreditorPortalDebtorProfileQuery } from "features/accountsTable/creditorPortal/accountsTableAPI";
import { useUserType } from "features/auth";
import { useFetchAllDebtorUdfCustomFieldsQuery } from "features/userDefinedFields/agencyPortal/debtorUserDefinedFieldsAPI";
import moment from "moment-timezone";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const StyledCard = styled.div`
  margin-top: 0px;
  margin-left: 8px;
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledHeaderText = styled.h4`
   ;
`;

export function DebtorInfoCard({ profileType }) {
  const { isAgencyUserType } = useUserType();
  const { debtorId } = useParams();
  const [isEditing, setIsEditing] = useState(false);
  const { data: agencyDebtorPersonalInfo, isSuccess: agencyIsSuccess } = useFetchDebtorProfileQuery(
    { debtorId },
    { skip: !isAgencyUserType },
  );
  const { data: debtorUdfCustomFields } = useFetchAllDebtorUdfCustomFieldsQuery();
  const { data: creditorPortalDebtorPersonalInfo, isSuccess: creditorPortalIsSuccess } =
    useFetchCreditorPortalDebtorProfileQuery({ debtorId }, { skip: isAgencyUserType });
  const [debtorPersonalInfo, isSuccess] = isAgencyUserType
    ? [agencyDebtorPersonalInfo, agencyIsSuccess]
    : [creditorPortalDebtorPersonalInfo, creditorPortalIsSuccess];

  const [updateDebtorProfile, { isLoading }] = useUpdateDebtorProfileMutation();

  const [debtorForm] = Form.useForm();
  const formItemLayout = {
    labelCol: {
      xs: { span: 20 },
      sm: { span: 8 },
    },
  };

  const toggleIsEditing = async () => {
    if (isEditing) {
      const values = await debtorForm.validateFields();

      const result = await updateDebtorProfile({
        debtorId,
        ...values,
      });
      if ("error" in result) {
        debtorForm.setFields(formatError(result.error));
        message.error("Could not update personal information");
        return;
      }
      setIsEditing(false);
    } else {
      setIsEditing(true);
      debtorForm.setFieldsValue(debtorPersonalInfo);
    }
  };

  const onCancel = () => {
    setIsEditing(false);
  };

  const disabledDobDate = (current) => {
    const today = moment().startOf("day").add(1, "day");
    // Can not select today and later.
    return current && current >= today;
  };

  const customFieldsElements = useMemo(
    () =>
      getCustomFieldsItems(debtorPersonalInfo.customFields, debtorUdfCustomFields, {
        includeAll: isEditing,
      }).map(({ field, slug }) => {
        return (
          <DisplayComponent
            key={field.slug}
            isEditing={isEditing}
            slice={debtorPersonalInfo}
            label={field.name}
            name={["customFields", slug]}
            placeholder=""
            outputFormatter={customFieldTypeFormatFunctions[field.type]}
            // There's some weirdness with the boolean type's required prop, fixing later
            required={field.isRequired && field.type !== "boolean"}
          >
            {udfToComponentMap[field.type]}
          </DisplayComponent>
        );
      }),
    [debtorPersonalInfo, debtorUdfCustomFields, isEditing],
  );

  const debtorComponents = (
    <>
      <DisplayComponent
        isEditing={isEditing}
        slice={debtorPersonalInfo}
        label="First Name"
        name="firstName"
        rules={[{ required: true }]}
      />
      <DisplayComponent
        isEditing={isEditing}
        slice={debtorPersonalInfo}
        label="Middle Name"
        name="middleName"
      />
      <DisplayComponent
        isEditing={isEditing}
        slice={debtorPersonalInfo}
        label="Last Name"
        name="lastName"
        rules={[{ required: true }]}
      />
      <DisplayComponent
        isEditing={isEditing}
        slice={debtorPersonalInfo}
        label="SSN"
        name="ssn"
        placeholder="123-45-6789"
      />
      <DisplayComponent
        isEditing={isEditing}
        slice={debtorPersonalInfo}
        label="Date of Birth"
        name="dob"
        isDate
      >
        <AktDatePicker disabledDate={disabledDobDate} />
      </DisplayComponent>
      <DisplayComponent
        isEditing={isEditing}
        slice={debtorPersonalInfo}
        label="Driver's License"
        name="driversLicenseNumber"
        placeholder=""
      />
      <DisplayComponent
        isEditing={isEditing}
        slice={debtorPersonalInfo}
        label="Date of Decease"
        name="deceasedDate"
        isDate
      >
        <AktDatePicker disabledDate={disabledDobDate} />
      </DisplayComponent>
      {customFieldsElements}
    </>
  );

  const commercialComponents = (
    <>
      <DisplayComponent
        isEditing={isEditing}
        slice={debtorPersonalInfo}
        label="Company"
        name="companyName"
        placeholder="Enter Company Name..."
        rules={[{ required: true }]}
      />
      <DisplayComponent
        isEditing={isEditing}
        slice={debtorPersonalInfo}
        label="EIN"
        name="ein"
        placeholder="Enter EIN..."
      />
      <DisplayComponent
        isEditing={isEditing}
        slice={debtorPersonalInfo}
        label="Default Email"
        name="email"
        placeholder="example@example.com"
      />
      {customFieldsElements}
    </>
  );

  return (
    <StyledCard>
      <StyledHeader>
        <StyledHeaderText>
          {profileType === "commercial" ? "Company Info" : "Personal Info"}
        </StyledHeaderText>
        {!isEditing && isAgencyUserType && (
          <Button
            loading={isLoading}
            type="link"
            icon={<EditOutlined />}
            onClick={toggleIsEditing}
          />
        )}
      </StyledHeader>
      {isSuccess && (
        <Form layout="horizontal" labelAlign="left" {...formItemLayout} form={debtorForm}>
          {profileType === "commercial" ? commercialComponents : debtorComponents}
        </Form>
      )}
      {isEditing && (
        <StyledButtonContainer>
          <Space>
            <Button onClick={onCancel}>Cancel</Button>
            <Button loading={isLoading} onClick={toggleIsEditing} type="primary">
              Save
            </Button>
          </Space>
        </StyledButtonContainer>
      )}
    </StyledCard>
  );
}
