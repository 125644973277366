import { Button, Col, Form, Row } from "antd";
import TableFilterItem from "components/tableFilter/tableFilterItem";
import styled from "styled-components";

const StyledRow = styled(Row)`
  align-items: baseline;
  padding: 12px;
  border-left: 1px solid #f0f0f0;
  border-top: 1px solid #f0f0f0;
  background-color: #fff;
  min-width: 250px;
`;

const StyledContainer = styled.div`
  height: calc(100vh - 230px);
  overflow: scroll;
  padding: 12px;
  border-left: 1px solid #f0f0f0;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  background-color: #fff;
  margin-bottom: 12px;
  min-width: 250px;
`;

function TableFilter(props) {
  /**
   * The idea of this component is to receive a structured list of the filters and formats and render them.
   * It will also receive a React state and setter to change the state of the filters.
   * Expected filteredStructure structure: A list of table filter items
   * Please check TableFilterItem documentation on how to structure the items
   */
  const { filteredStructure, onReset } = props;
  const [form] = Form.useForm();

  function dispatchReset() {
    form.resetFields();
    onReset();
  }

  return (
    <Col>
      <StyledRow justify="space-between">
        <h3>Filters</h3>
        <Button type="link" onClick={() => dispatchReset()}>
          Reset
        </Button>
      </StyledRow>
      <StyledContainer>
        <Form form={form} layout="vertical">
          {filteredStructure.map((item) => (
            <TableFilterItem item={item} />
          ))}
        </Form>
      </StyledContainer>
    </Col>
  );
}
export default TableFilter;
